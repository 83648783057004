// Dependencies
import { useEffect } from 'react';

let AttachSignature = () => {
  // Variable

  // Methods

  // Hooks
  useEffect(() => {});

  // Template
  return (
    <div class="modal-content">
      <div class="modal-header border-0 align-items-start px-5 pt-5 d-inline-block">
        <img class="rounded-circle object-fit-cover section--signature-img d-inline-block me-3" src="img/icon-question.png" alt="verify account icon"/>
        <div class=" d-inline-block">
          <h1 class="modal-title fs-18 fw-bold mb-3 mb-md-0" id="modal-attachSignatureLabel">You are about to attach your signature</h1>
          <p class="fs-14">This action cannot be undone. Do you wish to continue?</p>
        </div>
      </div>
      <div class="modal-footer border-0 p-5 w-100">
        <div class="row m-0 w-100">
          <div class="col-md-12 p-0 d-flex flex-column position-relative">
            <div class="row mb-0 w-100 mx-0 justify-content-center">
              <div class="col-md-5 px-0 pe-md-1 d-flex mb-3 mb-md-0 flex-column position-relative">
                <button class="button fs-14 border border-2 rounded-2 fw-bold d-flex align-items-center justify-content-center border--blue fc-blue" type="button" onClick={() => window.location.reload()} aria-label="Close">NO, GO BACK</button>
              </div>
              <div class="col-md-5 px-0 d-flex ps-md-1 flex-column position-relative">
                <button class="button bg--blue rounded-2 text-white fw-bold d-flex align-items-center justify-content-center text-uppercase fs-14" type="submit">YES, PROCEED</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
    
}

export default AttachSignature;