// Dependencies
import { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';

import AddRules from './rules-add';
import EditRules from './rules-edit';
import VerifyAccount from './verify-account';
import ChangeAccountType from './change-account-type';
import ChangeApplicationStatus from './change-application-status';
import AttachSignature from './attach-signature';
import OtherServicesModal from './other-services';

let Modal = ({
  displayModal=false,
  onHide,
  details={},
  id,
  rules=false,
  add=false,
  edit=false,
  signup=false,
  verify=false,
  superadmin=false,
  changeaccounttype=false,
  admin=false,
  changeapplicationstat=false,
  attachsign=false,
  otherservices=false}) => {
  // Variable

  // Methods
  const setModalId = () => {
    if (rules && add) {
      return {
        id: 'modal-addRules',
        aria_label: 'modal-addRulesLabel'
      };
    }
    
    if (rules && edit) {
      return {
        id: 'modal-editRules',
        aria_label: 'modal-editRulesLabel'
      };
    }

    if (signup && verify) {
      return {
        id: 'modal-verifyAccount',
        aria_label: 'modal-verifyAccountLabel'
      };
    }

    if (superadmin && changeaccounttype) {
      return {
        id: 'modal-changeAccountType',
        aria_label: 'modal-changeAccountTypeLabel'
      };
    }

    if ((superadmin || admin) && changeapplicationstat) {
      return {
        id: 'modal-changeApplicationStat',
        aria_label: 'modal-changeApplicationStatLabel'
      };
    }

    if ((superadmin || admin) && attachsign) {
      return {
        id: 'modal-attachSignature',
        aria_label: 'modal-attachSignatureLabel'
      };
    }

    if (otherservices) {
      return {
        id: 'modal-otherServices',
        aria_label: 'modal-otherServices'
      };
    }

    return {
      id: '',
      aria_label: ''
    };
  }

  // Hooks
  useEffect(() => {});

  // Template
  return (
    <Dialog
      visible={displayModal}
      modal
      onHide={onHide}
      style={{width: '50vw'}}
      content={ () => (
        <div class="card">
          { (rules && add) && <AddRules/> }
          { (rules && edit) && <EditRules id={id} details={details}/> }
          { (signup && verify) && <VerifyAccount details={details}/> }
          { (superadmin && changeaccounttype) && <ChangeAccountType id={id}/> }
          { ((superadmin || admin) && changeapplicationstat) && <ChangeApplicationStatus id={id} details={details}/> }
          { ((superadmin || admin) && attachsign) && <AttachSignature/> }
          { otherservices && <OtherServicesModal/> }
          {/* <EditRules/> */}
        </div>
      )}
    />
    // <div class="modal border-0 fade" id={ setModalId().id } tabindex="-1" aria-labelledby={ setModalId().aria_label } aria-hidden="true">
    //   <div class="modal-dialog modal-dialog-centered">
    //     { (rules && add) && <AddRules/> }
    //     { (rules && edit) && <EditRules id={id} details={details}/> }
    //     { (signup && verify) && <VerifyAccount details={details}/> }
    //     { (superadmin && changeaccounttype) && <ChangeAccountType id={id}/> }
    //     { ((superadmin || admin) && changeapplicationstat) && <ChangeApplicationStatus/> }
    //     { ((superadmin || admin) && attachsign) && <AttachSignature/> }
    //     { otherservices && <OtherServicesModal/> }
    //     {/* <EditRules/> */}
    //   </div>
    // </div>
  );
    
}

export default Modal;