import React, { useState } from 'react'

function LandingInstructionsModal({ isLandingModal, setIsLandingModal }) {


    const [isModal, setIsModal] = useState(true);

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log(`Name: ${Name}, Birthdate: ${Birthdate}`);
        setIsLandingModal(false);
        // You can save the values here
    };

  return (

    <div>

      {/* Overlay Modal */}
      {isLandingModal && (

      <div className="fixed  h-full w-full flex flex-col justify-center items-center backdrop-blur-sm backdrop-filter overflow-hidden bg-opacity-60 z-50">

            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:h-[80vh] lg:w-[80vw] h-[85vw] w-[85vw] flex flex-col justify-start items-center lg:p-20 z-500 rounded-3xl backdrop-blur-3xl bg-white backdrop-filter overflow-hidden bg-opacity-30 drop-shadow-lg text-white ">

            <div>
                <form onSubmit={handleSubmit} className="flex flex-col items-center gap-7">

                <div className="text-[20px] font-bold drop-shadow-lg  select-none text-black"> 
                    INSTRUCTIONS
                </div>

                <div className="flex flex-row justify-center items-center gap-5">
                    <div className="p-2 text-[15px] font-bold drop-shadow-lg  text-black"> 
                    insert instructions
                    </div>
                </div>

                <div className="text-[20px] font-bold drop-shadow-lg  select-none text-black"> 
                    TERMS AND CONDITIONS
                </div>

                <div className="flex flex-row justify-center items-center gap-5">
                    <div className="p-2 text-[15px] font-bold drop-shadow-lg  text-black"> 
                    insert terms and conditions
                    </div>
                </div>

                <button className="p-2 bg-[#005DB3] text-white rounded-md" onClick={() => setIsModal(false)}>Accept</button>

                </form>
            </div>

            </div>

      </div>

      )}

    </div>
  )
}

export default LandingInstructionsModal