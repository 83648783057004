import { Outlet } from "react-router-dom";

function Template() {
  return (
    <div>

        <Outlet />

    </div>
  )
}

export default Template