import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
// import { Chart, LineElement, PointElement, LineController, LinearScale, CategoryScale, Tooltip, Legend } from 'chart.js';
import { useEffect } from 'react';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

/**
 * Line Chart Component
 * 
 * @returns Template
 * 
 * @author Angelo C. Bustamante
 * @since 04/09/2024 
 */
let LineChart = ({ value }) => {
  // Variable
  let objData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        data: value,
        borderColor: '#005CB2',
        tension: 0.4
      }
    ]
  }
  let objOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          },
        }
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          }
        }
      }
    }
  }

  // Methods

  // Hooks
  useEffect(() => {}, [])

  // Template
  return (
    <Line data={objData} options={objOptions} />
  );
    
}

export default LineChart;