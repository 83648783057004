// Dependencies
import { useEffect } from 'react';
import Button from '../../components/button';

let RetireeApplicationHistory = () => {
  // Variable

  // Methods

  // Hooks
  useEffect(() => {}, []);

  // Template
  return (
    <div class="retiree-section-container">
      <div class="banner retiree-application application-history">
        <div class="page-text container-fluid">
          <div class="fw-bold page-title">Making the Philippines a 
            <br/>Globally-Preferred Retirement Destination</div>
        </div>
      </div>
      <div class="container-fluid retiree-section-content application-history section h-full">
        <div class="d-flex header mb-5">
          <p class="fs-24 fw-bold mt-3 mb-4">Your Submitted Application Form</p>
          <div class="badge-container">
            <div class="application-stat white text-center fs-12"><span class="fw-bold">VISA TYPE:&nbsp;</span>SMILE</div>
            <div class="application-stat yellow text-center fs-12  fw-bold fc-darkYellow">PROCESSING</div>
          </div>
        </div>
        <nav class="nav nav-pills">
            <a class="fs-md-14 text-center nav-link active" id="nav-principal-retiree" data-bs-toggle="tab" data-bs-target="#principal-retiree" aria-current="page" type="button" role="tab" aria-controls="principal-retiree" aria-selected="true">
                <div class="tab-pill-image me-2">
                    <span class="tab-pill-icon w-100 h-100 d-block"></span>
                </div>
                <span>PRINCIPAL RETIREE</span>
            </a>
            <a class="fs-md-14 text-center nav-link" id="nav-family-member" data-bs-toggle="tab" data-bs-target="#family-member" type="button" role="tab" aria-controls="family-member"
                aria-selected="false">
                <div class="tab-pill-image me-2">
                    <span class="tab-pill-icon w-100 h-100 d-block"></span>
                </div>
                <span>FAMILY MEMBER</span>
            </a>
            <a class="fs-md-14 text-center nav-link" id="nav-parents" data-bs-toggle="tab" data-bs-target="#parents" type="button" role="tab"
                aria-controls="parents" aria-selected="false">
                <div class="tab-pill-image me-2">
                    <span class="tab-pill-icon w-100 h-100 d-block"></span>
                </div>
                <span>PARENTS</span>
            </a>
            <a class="fs-md-14 text-center nav-link" id="nav-education-and-career" data-bs-toggle="tab" data-bs-target="#education-and-career"
                type="button" role="tab" aria-controls="education-and-career" aria-selected="false">
                <div class="tab-pill-image me-2">
                    <span class="tab-pill-icon w-100 h-100 d-block"></span>
                </div>
                <span>EDUCATION AND CAREER</span>
            </a>
            <a class="fs-md-14 text-center nav-link" id="nav-attachments" data-bs-toggle="tab" data-bs-target="#attachments" type="button"
                role="tab" aria-controls="attachments" aria-selected="false">
                <div class="tab-pill-image me-2">
                    <span class="tab-pill-icon w-100 h-100 d-block"></span>
                </div>
                <span>ATTACHMENTS</span>
            </a>
        </nav>
        <div class="tab-content">
            <div class="tab-pane fade show active" id="principal-retiree" role="tabpanel" aria-labelledby="nav-principal-retiree" tabindex="0">
                <div class="panel bg-white">
                    <h5 class="fw-bold">Basic Information</h5>
                    <div class="row mb-2 mt-4 mb-sm-4">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">FULL NAME</p>
                            <p class="fs-16 fw-bold text-capitalize">Enrico Dela Cruz</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">ALIAS (AKA)</p>
                            <p class="fw-bold fs-16">12/12/2023</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">RELIGION</p>
                            <p class="fw-bold fs-16">Catholic</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">GENDER</p>
                            <p class="fw-bold fs-16">Female</p>
                        </div>
                    </div>
                    <div class="row mb-2 mb-sm-4">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">DATE OF BIRTH</p>
                            <p class="fs-16 fw-bold text-capitalize">10/24/1999</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">PLACE OF BIRTH</p>
                            <p class="fw-bold fs-16">Cardona, Rizal</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">NATIONALITY</p>
                            <p class="fw-bold fs-16">Filipino</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">ID NO.</p>
                            <p class="fw-bold fs-16">SSS011291</p>
                        </div>
                    </div>
                    <div class="row mb-2 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">CIVIL STATUS</p>
                            <p class="fs-16 fw-bold text-capitalize">Single</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">HEIGHT</p>
                            <p class="fw-bold fs-16">5 ft.</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">WRIGHT</p>
                            <p class="fw-bold fs-16">150 lbs</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white bordered-panel">
                    <h5 class="fw-bold">Passport Information</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">PASSPORT NO.</p>
                            <p class="fs-16 fw-bold text-capitalize">PSA2N23123</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">PLACE OF ISSUE</p>
                            <p class="fw-bold fs-16">Cardona, Rizal</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">DATE OF ISSUE</p>
                            <p class="fw-bold fs-16">12/25/2023</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">VALID UNTIL</p>
                            <p class="fw-bold fs-16">12/24/2028</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white">
                    <h5 class="fw-bold">Address Information</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-12 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">HOME COUNTRY ADDRESS</p>
                            <p class="fs-16 fw-bold text-capitalize">1749 Escuela Street, Guadalupe Nuevo, Pasig City, Metro Manila</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">TELEPHONE NO.</p>
                            <p class="fs-16 fw-bold text-capitalize">PSA2N23123</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">FAX NO.</p>
                            <p class="fw-bold fs-16">PSA2N23123</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">MOBILE NO.</p>
                            <p class="fw-bold fs-16">09554639043</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">E-MAIL</p>
                            <p class="fw-bold fs-16">adrian@gmail.com</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-12 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">PRIMARY ADDRESS IN THE PHILIPPINES</p>
                            <p class="fs-16 fw-bold text-capitalize">1749 Escuela Street, Guadalupe Nuevo, Pasig City, Metro Manila</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-12 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">SECONDARY ADDRESS IN THE PHILIPPINES</p>
                            <p class="fs-16 fw-bold text-capitalize">1749 Escuela Street, Guadalupe Nuevo, Pasig City, Metro Manila</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">TELEPHONE NO.</p>
                            <p class="fs-16 fw-bold text-capitalize">PSA2N23123</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">FAX NO.</p>
                            <p class="fw-bold fs-16">PSA2N23123</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">MOBILE NO.</p>
                            <p class="fw-bold fs-16">09554639043</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">E-MAIL</p>
                            <p class="fw-bold fs-16">adrian@gmail.com</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white bordered-panel">
                    <h5 class="fw-bold">Entry to the Philippines</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-4 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">DATE OF ARRIVAL IN THE PHILIPPINES</p>
                            <p class="fs-16 fw-bold text-capitalize">12/24/2023</p>
                        </div>
                        <div class="col-md-4">
                            <p class="fs-14 text-uppercase">EXPIRATION DATE OF TOURIST VISA</p>
                            <p class="fw-bold fs-16">12/24/2023</p>
                        </div>
                        <div class="col-md-4">
                            <p class="fs-14 text-uppercase">ENTRY VISA TO THE PHILIPPINES</p>
                            <p class="fw-bold fs-16">Sample text</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-4 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">VISITED PHILIPPINES PRIOR TO THIS TRAVEL?</p>
                            <p class="fs-16 fw-bold text-capitalize">Yes • Tourist Visa</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white rounded-bottom-4">
                    <h5 class="fw-bold">Last Three Years Residence in the Philippines</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">PERIOD OF STAY</p>
                            <p class="fs-16 fw-bold text-capitalize">12/2020 to 12/2023</p>
                        </div>
                        <div class="col-md-6">
                            <p class="fs-14 text-uppercase">ADDRESS</p>
                            <p class="fw-bold fs-16">1749 Escuela Street, Guadalupe Nuevo, Pasig City, Metro Manila </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="family-member" role="tabpanel" aria-labelledby="nav-family-member" tabindex="0">
                <div class="panel bg-white bordered-panel">
                    <h5 class="fw-bold">Spouse</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">FULL NAME</p>
                            <p class="fs-16 fw-bold text-capitalize">Helen Gomez</p>
                        </div>
                        <div class="col-md-2">
                            <p class="fs-14 text-uppercase">DATE OF BIRTH</p>
                            <p class="fw-bold fs-16">12/24/1999</p>
                        </div>
                        <div class="col-md-2">
                            <p class="fs-14 text-uppercase">AGE</p>
                            <p class="fw-bold fs-16">25 years old</p>
                        </div>
                        <div class="col-md-2">
                            <p class="fs-14 text-uppercase">ID NO.</p>
                            <p class="fw-bold fs-16">PSA2N23123</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">INCLUDED IN APPLICATION?</p>
                            <p class="fw-bold fs-16">Yes</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white rounded-bottom-4">
                    <h5 class="fw-bold">Child</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">FULL NAME</p>
                            <p class="fs-16 fw-bold text-capitalize">Helen Gomez</p>
                        </div>
                        <div class="col-md-2">
                            <p class="fs-14 text-uppercase">DATE OF BIRTH</p>
                            <p class="fw-bold fs-16">12/24/1999</p>
                        </div>
                        <div class="col-md-2">
                            <p class="fs-14 text-uppercase">AGE</p>
                            <p class="fw-bold fs-16">25 years old</p>
                        </div>
                        <div class="col-md-2">
                            <p class="fs-14 text-uppercase">ID NO.</p>
                            <p class="fw-bold fs-16">PSA2N23123</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">INCLUDED IN APPLICATION?</p>
                            <p class="fw-bold fs-16">Yes</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="parents" role="tabpanel" aria-labelledby="nav-parents" tabindex="0">
                <div class="panel bg-white">
                    <h5 class="fw-bold">Father</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">FULL NAME</p>
                            <p class="fs-16 fw-bold text-capitalize">Helen Gomez</p>
                        </div>
                        <div class="col-md-2">
                            <p class="fs-14 text-uppercase">AGE</p>
                            <p class="fw-bold fs-16">25 years old</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white bordered-panel">
                    <h5 class="fw-bold">Mother</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">FULL NAME</p>
                            <p class="fs-16 fw-bold text-capitalize">Helen Gomez</p>
                        </div>
                        <div class="col-md-2">
                            <p class="fs-14 text-uppercase">AGE</p>
                            <p class="fw-bold fs-16">25 years old</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white rounded-bottom-4">
                    <h5 class="fw-bold">Emergency Contact Person</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">FULL NAME</p>
                            <p class="fs-16 fw-bold text-capitalize">Helen Gomez</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">CONTACT NO.</p>
                            <p class="fw-bold fs-16">09554639043</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">NATIONALITY</p>
                            <p class="fw-bold fs-16">Filipino</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">RELATIONSHIP</p>
                            <p class="fw-bold fs-16">Mother</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="education-and-career" role="tabpanel" aria-labelledby="nav-education-and-career" tabindex="0">
                <div class="panel bg-white bordered-panel">
                    <h5 class="fw-bold">Education</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">COLLEGE/UNIVERSITY</p>
                            <p class="fs-16 fw-bold text-capitalize">Helen Gomez</p>
                        </div>
                        <div class="col-md-6">
                            <p class="fs-14 text-uppercase">SCHOOL AND LOCATION</p>
                            <p class="fw-bold fs-16">1749 Escuela Street, Guadalupe Nuevo, Pasig City, Metro Manila</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">FROM/TO</p>
                            <p class="fw-bold fs-16">06/2016 to 09/2020</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">HIGHSCHOOL</p>
                            <p class="fs-16 fw-bold text-capitalize">Helen Gomez</p>
                        </div>
                        <div class="col-md-6">
                            <p class="fs-14 text-uppercase">SCHOOL AND LOCATION</p>
                            <p class="fw-bold fs-16">1749 Escuela Street, Guadalupe Nuevo, Pasig City, Metro Manila</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">FROM/TO</p>
                            <p class="fw-bold fs-16">06/2016 to 09/2020</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">ELEMENTARY</p>
                            <p class="fs-16 fw-bold text-capitalize">Helen Gomez</p>
                        </div>
                        <div class="col-md-6">
                            <p class="fs-14 text-uppercase">SCHOOL AND LOCATION</p>
                            <p class="fw-bold fs-16">1749 Escuela Street, Guadalupe Nuevo, Pasig City, Metro Manila</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">FROM/TO</p>
                            <p class="fw-bold fs-16">06/2016 to 09/2020</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white">
                    <h5 class="fw-bold">Present Company / Business</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">NAME</p>
                            <p class="fs-16 fw-bold text-capitalize">Helen Gomez</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">CONTACT NO.</p>
                            <p class="fw-bold fs-16">09554639043</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white bordered-panel">
                    <h5 class="fw-bold">Other Plans/Future Actions in the Philippines</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-4 mb-2 mb-sm-0">
                            <p class="fs-16 fw-bold text-capitalize">Tourism, Employment, Investment</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white rounded-bottom-4">
                    <h5 class="fw-bold">Employment in the Last Three (3) Years</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-6 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">COMPANY NAME AND ADDRESS</p>
                            <p class="fs-16 fw-bold text-capitalize">Helen Gomez, Quezon City, Metro Manila</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">JOB TITLE</p>
                            <p class="fs-16 fw-bold text-capitalize">Web Developer</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">FROM/TO</p>
                            <p class="fs-16 fw-bold text-capitalize">06/2016 to 09/2020</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-6 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">COMPANY NAME AND ADDRESS</p>
                            <p class="fs-16 fw-bold text-capitalize">Helen Gomez, Quezon City, Metro Manila</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">JOB TITLE</p>
                            <p class="fs-16 fw-bold text-capitalize">Web Developer</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">FROM/TO</p>
                            <p class="fs-16 fw-bold text-capitalize">06/2016 to 09/2020</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="attachments" role="tabpanel" aria-labelledby="nav-attachments" tabindex="0">
                <div class="panel bg-white bordered-panel rounded-bottom-4">
                    <h5 class="fw-bold">Attachments
                        <div class="col-md-12 mt-4 mb-4">
                            <p class="fs-14 text-uppercase">POLICE CLEARANCE</p>
                            <p class="fs-16"><a href="" download class="link bg-transparent">policeclearance.jpg</a></p>
                        </div>
                        <div class="col-md-12 mb-4">
                            <p class="fs-14 text-uppercase">NBI CLEARANCE</p>
                            <p class="fs-16"><a href="" download class="link bg-transparent">policeclearance.jpg</a></p>
                        </div>
                        <div class="col-md-12 mb-4">
                            <p class="fs-14 text-uppercase">AFFIDAVIT OF UNDERTAKING</p>
                            <p class="fs-16"><a href="" download class="link bg-transparent">policeclearance.jpg</a></p>
                        </div>
                        <div class="col-md-12 mb-4">
                            <p class="fs-14 text-uppercase">MEDICAL CERTIFICATE</p>
                            <p class="fs-16"><a href="" download class="link bg-transparent">policeclearance.jpg</a></p>
                        </div>
                        <div class="col-md-12 mb-4">
                            <p class="fs-14 text-uppercase">BANK CERTIFICATION</p>
                            <p class="fs-16"><a href="" download class="link bg-transparent">policeclearance.jpg</a></p>
                        </div>
                        <div class="col-md-3 mb-4">
                            <p class="fs-14 text-uppercase">PASSPORT</p>
                            <p class="fs-16"><a href="" download class="link bg-transparent">policeclearance.jpg</a></p>
                        </div>
                    </h5>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
    
}

export default RetireeApplicationHistory;