// Dependencies
import { useEffect, useState } from 'react';
import { getRowId, icon } from '../../../js/util';
import { getRequest } from '../../../js/axios';

// Components
import Button from '../../components/button';
import Modal from '../../components/modal';
import Table from '../../components/table';
import IconButton from '../../components/iconbutton';
import { GET_APPLICATION } from '../../../constants/api-route';
import { AUTH_TOKEN } from '../../../constants/cookie';
import { getCookie } from '../../../js/cookie';
import { Link } from 'react-router-dom';

/**
 * Superadmin Rules
 * 
 * @returns template
 * 
 * @author Nicele Reyes
 * @since 04/08/2024 
 */
let SuperadminRules = ({title, description}) => {
  // Variable
  const [id, setId] = useState(null);
  const [attachVisible, setAttachVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [application, setApplication] = useState([]);
  const columns = [
    {field: 'chosenTimeSlot', header: 'TIMESTAMP'},
    {field: 'idNo', header: 'APPLICATION NO.'},
    {field: 'name', header: 'FULL NAME'},
    {field: 'nameContactPerson', header: 'MARKETER'}
  ];
  
  
  // Icons
  let moreIconRegular = 'img/icon-more-regular.png';
  let moreIconSolid = 'img/icon-more-solid.png';
  

  // Methods
  const actionColumnBody = (data) => {
    return (
        <div>
            <span onClick={() => {} } >
                <Link class="far fa-eye js-icon-button" to={`/superadmin/viewapplicant/${data.userID}`}/>
            </span>
            <IconButton icon={true} addtlButtonClass="ms-4 icon me-0" addtlIconClass="section--icon-more" dropdown={ true } img={ true } src="img/icon-more-regular.png" onMouseOver={(e) => e.target.src = moreIconSolid} onMouseOut={e => e.target.src = moreIconRegular}/> 
            <ul class="dropdown-menu p-0 dropdown--section dropdown--section-inset">
                <li><a class={`dropdown-item fs-16 row-id-${data.applicationID}`} type="button" onClick={(event) => updateApplicationStatus(event)}>Update application status</a></li>
                <li>
                    <hr class="dropdown-divider m-0" />
                </li>
                <li><a class="dropdown-item fs-16" type="button" onClick={() => setAttachVisible(true)}>Attach signature</a></li>
            </ul>
        </div> 
    );
  }

  const getApplications = (data) => {
    let applicationData = [];
    data.forEach((obj)=> {
      let objapplication= {
        userID: obj.userID,
        applicationID: obj.applicationID,
        chosenTimeSlot: new Date(obj.chosenTimeSlot).toLocaleDateString("en-US"),
        idNo: obj.idNo,
        name: `${obj.firstName} ${obj.lastName}`,
        nameContactPerson: obj.nameContactPerson,
        status: obj.status
      }
      applicationData.push(objapplication);
    })
    setApplication(applicationData);
  }

  const updateApplicationStatus = (event) => {
    setUpdateVisible(true);
    getRowId(event, (id) => {
      setId(id)
    });
  }

  // Hooks
  // componentDidMount
  useEffect(() => {
    icon();
  });

  // componentDidUpdate
  useEffect(() => {
    getRequest(GET_APPLICATION, getApplications, {
      [AUTH_TOKEN]: getCookie(AUTH_TOKEN)
    });
  }, []);

  // Template
  return (
    <div class="overflow-y-auto w-100 h-100 px-3 py-5 p-lg-5">
      <div class="m-0 p-0 w-100">
        <h2 class="fs-md-24 fs-24 fw-bold">{ title }</h2>
        <p class="fs-md-14 fs-12 mb-5">{ description }</p>
      </div>
      <Table class="table w-100" columns={columns} data={application} actionContent={actionColumnBody} status={true} statusID='status'/>
      <Modal displayModal={updateVisible} onHide={() => setUpdateVisible(false)} superadmin={true} changeapplicationstat={true} id={id} details={ {applicants: true} }/>
      <Modal displayModal={attachVisible} onHide={() => setAttachVisible(false)} superadmin={true} attachsign={true}/>
    </div>
  );
    
}

export default SuperadminRules;