// Dependencies
import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getCookie } from '../../js/cookie';
import { resetCookie, setCookieDetails, setInputValue, showPassword } from '../../js/util';
import { postRequest } from '../../js/axios';
import { USER_TYPE } from '../../constants/cookie';
import { SIGN_IN } from '../../constants/api-route';
import { ADMIN_PROFILE, MARKETER_PROFILE, RETIREE_HOME, SUPERADMIN_DASHBOARD } from '../../constants/react-route';
import { BLANK, STATUS_OK } from '../../constants';

// Components
import Button from '../components/button';
import QuickNav from '../components/quicknav';
import { ADMIN, ADMIN_CONSUL, ADMIN_EVALUATOR, MARKETER, RETIREE, SUPERADMIN } from '../../constants/api-table-user';
import { showErrorToast, showSuccessToast } from '../../js/toast';

/**
 * Login Page
 * @returns template
 * 
 * @author Angelo C. Bustamante
 * @since 04/06/2024 
 */
let Login = () => {
  // State
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Variable
  const navigate = useNavigate();
  

  // Methods
  const loginUser = () => {
    let body = {
      email: email,
      password: password
    };

    postRequest(SIGN_IN, body, (res) => {
      if (res.status === STATUS_OK) {
        setCookieDetails(res.auth_token, () => {
          setEmail(BLANK);
          setPassword(BLANK);
          navigate(navigateRoute(getCookie(USER_TYPE)));
          showSuccessToast('Login Successful');
        });
      } else if (res.status === 401) {
        showErrorToast('Invalid Credentials');
      } else {
        showErrorToast('Something Went Wrong');
      }
    })
  }

  const navigateRoute = (usertype) => {
    switch (usertype) {
      case SUPERADMIN:
        return SUPERADMIN_DASHBOARD;
      case ADMIN_CONSUL || ADMIN_EVALUATOR:
        return ADMIN_PROFILE;
      case MARKETER:
        return MARKETER_PROFILE;
      case RETIREE:
        return RETIREE_HOME;
      default:
        break;
    }
  }

  // Hooks
  // componentDidMount
  useEffect(() => {
    resetCookie();
    showPassword();
  }, []);

  
  // { sPage === 'login' ? 'Welcome' : 'Let’s get started' }
  // sPage === 'login' ? 'Log in to your account' : 'Create your account'

  // Template
  return (
      <div className="section--login position-relative bg--blue min-vh-100 d-flex align-items-center justify-content-center container-fluid p-0">
          <div className="start-0 top-0 col min-vh-100">
            <iframe className="d-none d-lg-flex min-vh-100 w-100" src="https://www.youtube.com/embed/K2Cjf8gHH3Y?si=wca7Q8LELZ5E-pvV&amp;amp;controls=0&amp;autoplay=1&amp;mute=1&amp;loop=1&amp;playlist=K2Cjf8gHH3Y" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen="allowFullScreen"></iframe>
          </div>
          <div className="p-0 p-sm-5 d-flex flex-column col-lg col-12 container-fluid vh-100">
            <div className='bg--login h-100 d-flex align-items-center'>
            <div className="card p-5 shadow-lg rounded-4 m-auto">
              <div className="d-flex align-items-center justify-content-between mb-4">
                  <div className='d-flex flex-column'>
                    <h2 className="fw-bold fs-md-24 fs-20"> Welcome</h2>
                    <span className="fs-md-14 fs-12">Login to your account.</span>
                  </div>
                
                  <div className='company-logo'>
                    <img className="w-100 h-100 object-fit-contain" src="./icon/logo.png" alt=""/>
                  </div>
                </div>

                <form class="form mt-4">
                  <div class="d-flex flex-column position-relative mb-3">
                    <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="email-adress" type="text" placeholder="Enter your email address" autoComplete="" onChange={(event) => setInputValue(setEmail, event)}/>
                    <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="email-adress">Email Address</label>
                  </div>
                  <div class="d-flex flex-column position-relative">
                    <input class="form__input form__input--password p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="password" type="password" placeholder="Enter your password" onChange={(event) => setInputValue(setPassword, event)}/>
                    <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute" for="password">Password</label>
                    <span class="fas fa-lock position-absolute" id="js-lock-password"></span>
                  </div>
                  <Button text="Log in" addtlButtonClass="w-100 mt-5 mb-3" addtlLabelClass="fs-12" onClickAction={ loginUser }/>
                  <span class="fs-md-14 fs-12 d-block text-center" id="js-signup-container">Don’t have an account? Click <Link class="link fw-bold fc-green" to="/signup">here</Link><span> to sign up.</span></span>           
                </form>
              </div>
            </div>
              
          </div>
          {/* <QuickNav/> */}
      </div>
  );
}

export default Login;
