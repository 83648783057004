// Dependencies
import { useEffect, useState } from 'react';
import { getRowId, icon } from '../../../js/util';
import { getRequest } from '../../../js/axios';


// Components
import Modal from '../../components/modal';
import Table from '../../components/table';
import IconButton from '../../components/iconbutton';
import { GET_RENEWAL } from '../../../constants/api-route';
import { Link, useNavigate } from 'react-router-dom';
import { getCookie } from '../../../js/cookie';
import { ADMIN_TYPE } from '../../../constants/cookie';
import { EVALUATOR } from '../../../constants';

let AdminEvalRenewals = ({title, description}) => {
  // Variable
  const [id, setId] = useState(null);
  const [attachVisible, setAttachVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [renewals, setrenewals] = useState([]);
  const navigate = useNavigate();
  const columns = [
    {field: 'idNo', header: 'APPLICATION NO.'},
    {field: 'chosenTimeSlot', header: 'TIMESTAMP'},
    {field: 'name', header: 'FULL NAME'}
  ];
  
  
  // Icons
  let moreIconRegular = 'img/icon-more-regular.png';
  let moreIconSolid = 'img/icon-more-solid.png';

  // Methods
  const actionColumnBody = (data) => {
    return (<div>
      <span onClick={() => {} } >
        <Link class="far fa-eye js-icon-button" to={`/admin/viewapplicant/${data.userID}`}/>
      </span>
      <IconButton icon={true} addtlButtonClass="ms-4 icon me-0" addtlIconClass="section--icon-more" dropdown={ true } img={ true } src="img/icon-more-regular.png" onMouseOver={(e) => e.target.src = moreIconSolid} onMouseOut={e => e.target.src = moreIconRegular}/> 
      <ul class="dropdown-menu p-0 dropdown--section dropdown--section-inset">
      <li><a class={`dropdown-item fs-16 row-id-${data.renewalID}`} type="button" onClick={(event) => updateRenewalStatus(event)}>Update application status</a></li>
        <li>
            <hr class="dropdown-divider m-0" />
        </li>
        <li><a class="dropdown-item fs-16" type="button" onClick={() => setAttachVisible(true)}>Attach signature</a></li>
      </ul>
  </div> );
  }

  const getrenewals = (data) => {
    let renewalData = [];
    data.forEach((obj)=> {
      let objrenewal = {
        userID: obj.userID,
        renewalID: obj.renewalID,
        chosenTimeSlot: obj.chosenTimeSlot,
        idNo: obj.idNo,
        name: `${obj.firstName} ${obj.lastName}`,
        status: obj.status
      }
      renewalData.push(objrenewal);
    })
    setrenewals(renewalData);

  }
  
  const updateRenewalStatus = (event) => {
    setUpdateVisible(true);
    getRowId(event, (id) => {
      setId(id)
    });
  }

  // Hooks
  // componentDidMount
  useEffect(() => {
    icon();
  });

  // componentDidUpdate
  useEffect(() => {
    getRequest(GET_RENEWAL, getrenewals);
    // (getCookie(ADMIN_TYPE) !== EVALUATOR && navigate('/error'));
  }, []);


  // Template
  return (
    <div class="overflow-y-auto w-100 h-100 px-3 py-5 p-lg-5">
      <div class="m-0 p-0 w-100">
        <h2 class="fs-md-24 fs-24 fw-bold">{ title }</h2>
        <p class="fs-md-14 fs-12 mb-5">{ description }</p>
      </div>
      <Table class="table w-100" columns={columns} data={renewals} actionContent={actionColumnBody} status={true} statusID='status'/>
      <Modal displayModal={updateVisible} onHide={() => setUpdateVisible(false)} id={id} details={ {applicants: false} } superadmin={true} changeapplicationstat={true}/>
      <Modal displayModal={attachVisible} onHide={() => setAttachVisible(false)} superadmin={true} attachsign={true}/>
    </div>
  );
    
}

export default AdminEvalRenewals;