import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    barangay      : null,
    birthDate     : null,
    buildingNo    : null,
    city          : null,
    country       : null,
    email         : null,
    mobileNumber  : null,
    name          : null,
    profilePicture: null,
    signature     : null,
    state         : null,
    street        : null,
    superAdminID  : null,
    userID        : null,
    usertype      : null
  },
};

export const slice = createSlice({
  name: 'slice',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      console.log(state.user);
    },
  }
});

export const { setUser } = slice.actions;
export default slice.reducer;