export const USER_TYPE = 'usertype';
export const ADMIN_TYPE = 'adminType';
export const USER_ID = 'userID';
export const SUPERADMIN_ID = 'superAdminID';
export const AUTH_TOKEN = 'auth_token';
export const COOKIES = [
    USER_TYPE,
    ADMIN_TYPE,
    USER_ID,
    SUPERADMIN_ID,
    AUTH_TOKEN
]