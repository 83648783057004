// Dependencies
import { useEffect } from 'react';
import Button from '../../components/button';

import { Link } from "react-router-dom";

let RetireeApplication = () => {
  // Variable

  // Methods

  // Hooks
  useEffect(() => {}, []);

  // Template
  return (
    <div>

      <div class="banner position-relative">
        <div class="section__container mx-auto w-100 h-100 d-flex align-items-center text-white px-3 px-md-4">
          <h2 class="fw-bold page-title fs-md-36 fs-24">Making the Philippines a <br/>Globally-Preferred Retirement Destination</h2>
          
        </div>
        <div class="banner__image h-100 w-100 position-absolute top-0 z-n1">
          <img class="w-100 h-100 object-fit-cover" src="img/bg-application-form.png"></img>
        </div>
      </div>
      <div class="section section__container position-relative z-1 bg-white mx-auto w-100 h-100 px-3 px-md-4">
        <ul class="d-flex flex-column flex-md-row justify-content-center align-items-center text-white">
          <li class="rounded-6 me-md-4">
            <Link to="/retiree/application/new_application/form" className='button button--retiree bg--blue d-flex flex-column align-items-center justify-content-center rounded-6 px-4 py-5'>
              <div class="button__image">
                <img class="object-fit-cover h-100 w-100" src="img/icon-apply-new.png" alt="Apply New Icon" />
              </div>
              <span class="fs-14 fw-bold text-center mt-3">APPLY FOR NEW APPLICATION</span>
            </Link>
          </li>

          <li class="mt-4 mt-md-0 rounded-6 me-md-4">
            <Link to="/retiree/application/renewal/form" className='button button--retiree bg--blue d-flex flex-column align-items-center justify-content-center rounded-6 px-4 py-5'>
              <div class="button__image">
                <img class="object-fit-cover h-100 w-100" src="img/icon-apply-renewal.png" alt="Apply New Icon" />
              </div>
              <span class="fs-14 fw-bold text-center mt-3">APPLY FOR RENEWAL</span>
            </Link>
          </li>
        </ul>
      </div>

      <div class="container-fluid retiree-section-content application-history section">
        <p class="fs-24 fw-bold mt-3 mb-4">Applications History</p>
        <div class="application-item mb-4 flex-column flex-md-row">
          <div class="d-flex fw-bold align-items-center col-md-6 col-lg-4 col-12 justify-content-between justify-content-md-start">
          <span class="col-6 col-lg-8">Application #PRA201021</span>
            <div class="application-stat red text-center fs-12 col-6 col-md-4">DENIED</div>
          </div>
          <Link to="/retiree/applicationhistory" className='mt-4 mt-sm-0'>
            <button class="button bg--blue rounded-2 text-white fw-bold w-100" data-bs-toggle="" data-bs-target=""><span class="text-uppercase fs-md-14 fs-12">VIEW APPLICATION FORM</span></button>
          </Link>
        </div>
        <div class="application-item mb-4 flex-column flex-md-row">
          <div class="d-flex fw-bold align-items-center col-md-6 col-lg-4 col-12 justify-content-between justify-content-md-start">
            <span class="col-6 col-lg-8">Application #PRA201021</span>
            <div class="application-stat green text-center fs-12 col-6 col-md-4">APPROVED</div>
          </div>
          <Link to="/retiree/applicationhistory" className='mt-4 mt-sm-0'>
            <button class="button bg--blue rounded-2 text-white fw-bold w-100" data-bs-toggle="" data-bs-target=""><span class="text-uppercase fs-md-14 fs-12">VIEW APPLICATION FORM</span></button>
          </Link>
        </div>
        <div class="application-item mb-4 flex-column flex-md-row">
          <div class="d-flex fw-bold align-items-center col-md-6 col-lg-4 col-12 justify-content-between justify-content-md-start">
          <span class="col-6 col-lg-8">Application #PRA201021</span>
            <div class="application-stat yellow text-center fs-12 col-6 col-md-4">PROCESSING</div>
          </div>
          <Link to="/retiree/applicationhistory" className='mt-4 mt-sm-0'>
            <button class="button bg--blue rounded-2 text-white fw-bold w-100" data-bs-toggle="" data-bs-target=""><span class="text-uppercase fs-md-14 fs-12">VIEW APPLICATION FORM</span></button>
          </Link>
        </div>
      </div>
    </div>


  );
    
}

export default RetireeApplication;