import React from "react";

function ThankYou() {
  return (
    <div className="h-screen flex flex-row justify-center items-center bg-green-300">
      <a href="https://signup.metamap.com/?merchantToken=623e24701fbf38001c05ba92&flowId=6613553d0c578f001cdd4b60">
        <button>Verify your identity</button>
      </a>
    </div>
  );
}

export default ThankYou;
