

function StepTimeline({ step }) {

  // Image path
  const step1Image = process.env.PUBLIC_URL + '/application_step_icons/step1.png';
  const step2Image = process.env.PUBLIC_URL + '/application_step_icons/step2.png';
  const step3Image = process.env.PUBLIC_URL + '/application_step_icons/step3.png';
  const step4Image = process.env.PUBLIC_URL + '/application_step_icons/step4.png';
  const step5Image = process.env.PUBLIC_URL + '/application_step_icons/step5.png';
  const step6Image = process.env.PUBLIC_URL + '/application_step_icons/step6.png';
  const step7Image = process.env.PUBLIC_URL + '/application_step_icons/step7.png';
  const step8Image = process.env.PUBLIC_URL + '/application_step_icons/step8.png';
  const step9Image = process.env.PUBLIC_URL + '/application_step_icons/step9.png';

  // const items = ['Step 1: Preference', 'Step 2: Visa Type', 'Step 3: Principal Retiree', 'Step 4: Family Member', 'Step 5: Parent', 'Step 6: Education & Career', 'Step 7: Attachments', 'Step 8: Review', 'Step 9: Payment'];

  // const items = ['Step 1: Preference', 'Step 2: Visa Type', 'Step 3: Principal Retiree', 'Step 4: Family Member', 'Step 5: Parent', 'Step 6: Education & Career', 'Step 7: Attachments', 'Step 8: Delivery', 'Step 9: Payment'];

  const items = ['Step 1: Preference', 'Step 2: Visa Type', 'Step 3: Principal Retiree', 'Step 4: Family Member', 'Step 5: Education & Career', 'Step 6: Attachments', 'Step 7: Review', 'Step 8: Payment'];
  const images = [step1Image, step2Image, step3Image, step4Image, step6Image, step7Image, step8Image, step9Image];

  const activeStyling = "[#005DB3]";
  const inactiveStyling = "[#A9A9A8]";

  const lastActiveStyling = "bg-half-gradient-blue-reverse";
  const lastInactiveStyling = "bg-half-gradient-grey";


  const stepStylings = items.map((_, index) => index < step ? activeStyling : inactiveStyling);

  // styling for active links in timeline
  // active circle - bg-[#005DB3]
  // inactive circle - bg-[#A9A9A8]

  function mChangeText(index) {
    return items[step-1].split(": ")[index]
  }

  function mChangeImage() {
    return images[step-1]
  }

  return (
    <div>
        
          <div class="d-flex justify-content-center">

              <ol class="hidden sm:flex flex-row items-center justify-center h-auto w-auto bg-white p-6 min-h-[30vh] min-w-[80vw] rounded-full">
                  {items.map((item, index) => (
                    <div className="flex flex-row items-center justify-center max-w-[10vw] max-h-[7vh] pt-20">

                    <li key={index} class="relative mb-6 sm:mb-0">
                      <div className="flex items-center">
                        {/* LINE  */}
                        <div className={`hidden sm:flex h-1.5 w-full justify-center items-center ${index === 0 ? `bg-half-gradient-blue` : index === items.length - 1 ? (step === 8 ? lastActiveStyling : lastInactiveStyling) : `bg-${stepStylings[index]}`}`}>
                          <div class={`z-10 flex items-center justify-center h-20 w-20 rounded-full ring-white bg-${stepStylings[index]} shrink-0`}>
                            <img src={images[index]} alt="" className="object-contain object-center height-important" />
                          </div>
                        </div>
                      </div>
                        <div class="pt-12 flex flex-col items-center justify-start p-10 h-[8rem] text-center">
                          <h3 class={`text-[0.7rem] font-bold text-${stepStylings[index]}`}>{item.split(": ")[0]}:</h3>
                          <p class={`text-[0.7rem] font-bold text-${stepStylings[index]}`}>{item.split(": ")[1]}</p>
                        </div>
                    </li>

                    </div>
                  ))}
              </ol>


              {/* MOBILE STEPPER */}

              <ol class="lg:hidden flex flex-col items-center justify-center bg-white p-6 h-[50vw] w-[50vw] rounded-full">

                <div class={`z-10 flex flex-col items-center justify-center h-full w-full rounded-full ring-white bg-[#005DB3] shrink-0`}>

                  <img src={mChangeImage(step)} alt="" className="object-contain object-center mb-3" />

                  <div class="flex flex-col items-center justify-start text-center">
                    <h3 class={`text-[0.7rem] font-bold text-white`}>{mChangeText(0)}</h3>
                    <p class={`text-[0.7rem] font-bold text-white`}>{mChangeText(1)}</p>
                  </div>
                </div>

              </ol>

          </div>

    </div>
  )
}

export default StepTimeline