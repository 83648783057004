import { Outlet } from "react-router-dom";
import SuperadminPanel from "../components/panel/superadmin-panel";
import { useLocation, useNavigate } from 'react-router-dom';
import { verifySession } from '../../js/util';
import { useEffect } from "react";

function SuperadminTemplate() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    verifySession(location.pathname, (bSession) => {
      bSession && navigate('/error');
    });
  }, []);

  return (
    <div className="d-flex">
        <SuperadminPanel />
        <div className="section p-0 h-100 end-0 w-100 bg--light-gray">
            <Outlet />
        </div>
        
    </div>
  )
}

export default SuperadminTemplate