// Dependencies
import { Link } from "react-router-dom";
import { resetCookie } from "../../../js/util";
import { getCookie } from "../../../js/cookie";
import { USER_TYPE } from "../../../constants/cookie";
import { ADMIN_CONSUL, ADMIN_EVALUATOR } from "../../../constants/api-table-user";

/**
 * Panel Component
 * @returns template
 * 
 * @author Juliesse Ann Rasco
 * @since 04/09/2024 
 */
let AdminPanel = () => {
  // Variable
  let userType = getCookie(USER_TYPE)

  // Methods

  // Hooks
  // useEffect(() => {});

  // Template
  return (
    <div class="panel bg--blue text-white d-none d-md-flex flex-column justify-content-between position-fixed vh-100">
      <img class="company-logo mx-auto mb-4" src="icon/logo.png" alt=""/>
      <nav class="panel__navigation d-flex flex-column" aria-labelledby="panel-label">
        <h2 class="visually-hidden" id="panel-label">Sidebar Navigation</h2>
        { (userType === ADMIN_CONSUL) &&
          <Link to="consulnewapplicants" class="w-100 py-3 px-4 rounded-2 fw-semibold fs-14 d-flex align-items-center mb-4">
            <div class="panel__image me-3"><img class="w-100 h-100 d-block applicants" src="icon/icon-new-applicants.png" alt=""/></div>
            <span>New Applicant</span>
          </Link>
        }
        { (userType === ADMIN_CONSUL) &&
          <Link to="consulrenewals" class="w-100 py-3 px-4 rounded-2 fw-semibold fs-14 d-flex align-items-center mb-4">
            <div class="panel__image me-3"><img class="w-100 h-100 d-block renewals" src="icon/icon-renewals.png" alt=""/></div>
            <span>Renewals</span>
          </Link>
        }
        { ((userType === 'marketer') || (userType === ADMIN_EVALUATOR)) &&
          <Link to="newapplicants" class="w-100 py-3 px-4 rounded-2 fw-semibold fs-14 d-flex align-items-center mb-4">
          <div class="panel__image me-3"><img class="w-100 h-100 d-block applicants" src="icon/icon-new-applicants.png" alt=""/></div>
            <span>New Applicant</span>
          </Link>
        }
        { ((userType === 'marketer') || (userType === ADMIN_EVALUATOR)) &&
          <Link to="renewals" class="w-100 py-3 px-4 rounded-2 fw-semibold fs-14 d-flex align-items-center mb-4">
            <div class="panel__image me-3"><img class="w-100 h-100 d-block renewals" src="icon/icon-renewals.png" alt=""/></div>
            <span>Renewals</span>
          </Link>
        }
        <Link to="profile" class="w-100 py-3 px-4 rounded-2 fw-semibold fs-14 d-flex align-items-center mb-4">
          <div class="panel__image me-3"><img class="w-100 h-100 d-block profile" src="icon/icon-card-profile-white.png" alt=""/></div>
          <span>My Profile</span>
        </Link>
        <Link to="/" class="w-100 py-3 px-4 rounded-2 fw-semibold fs-14 d-flex align-items-center mb-4" onClick={resetCookie}>
          <div class="panel__image me-3"><img class="w-100 h-100 d-block logout" src="icon/icon-logout.png" alt=""/></div>
          <span>Logout</span>
        </Link>
      </nav>
      <div class="fs-12 text-center text-white">© 2024 All Rights Reserved.</div>
    </div>
  );
    
}

export default AdminPanel;