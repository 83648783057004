import "./tailwind/output.css"

function Error() {
  return (
    <div className="flex flex-row items-center justify-center bg-teal-700 text-white text-[4rem] h-screen">
      
      ERROR: Page Not Found

    </div>
  )
}

export default Error