// Dependencies
import { useEffect, useState } from 'react';
import { setCookieDetails, setInputValue } from '../../../js/util';
import { postRequest } from '../../../js/axios';
import { OPT_VERIFY } from '../../../constants/api-route';
import { STATUS_OK } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { RETIREE_HOME } from '../../../constants/react-route';
import { showErrorToast, showSuccessToast } from '../../../js/toast';

let VerifyAccount = ({ details={} }) => {
  // Variable
  const [code, setCode] = useState('');
  const navigate = useNavigate();

  // Methods
  const verifyOTP = () => {
    let params = {
      otp_id: details.otp_id,
      code  : code
    };
    postRequest(OPT_VERIFY, {}, (res) => {
      if (res.status === STATUS_OK) {
        setCookieDetails(res.auth_token, () => {
          showSuccessToast('Registered Successful');
          navigate(RETIREE_HOME);
        });
      } else if (res.status === 401) {
        showErrorToast('Invalid OTP Code');
      } else {
        showErrorToast('Something Went Wrong');
      }
    }, params);
  }

  // Hooks
  useEffect(() => {});

  // Template
  return (
    <div class="modal-content">
      <div class="modal-header border-0 flex-column align-items-center px-5 pt-5">
        <img class="object-fit-cover d-block section--verify-icon mb-4" src="img/icon-otp.png" alt=""/>
        <h1 class="modal-title fs-24 fw-bold mb-2">Verify your account</h1>
        <p class="section--subtitle fs-16">We have sent a code to your email <br/> {details.email}</p>
      </div>
      <div class="modal-body px-5">
        <div class="d-flex flex-column position-relative">
          <input class="form__input p-3 fs-14 border--gray border border-2 rounded-2" id="add-otp" type="text" placeholder="Enter OTP" onChange={(event) => setInputValue(setCode, event)}/>
        </div>
      </div>
      <div class="modal-footer border-0 p-5 w-100">
        <div class="row mb-3 m-0 w-100">
          <div class="col-md-12 p-0 d-flex flex-column position-relative">
            <button class="button bg--blue rounded-2 mb-3 text-white fw-bold d-flex align-items-center justify-content-center text-uppercase fs-14" type="submit" onClick={() => verifyOTP()}>
              SUBMIT
            </button>
            <span class="fs-md-14 fs-12 d-block text-center">Didn't receive the OTP? Click <a class="link fw-bold fc-green" href="/#">here</a><span> to resend again.</span></span>
          </div>
        </div>
      </div>
    </div>
  );
    
}

export default VerifyAccount;