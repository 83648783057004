// Dependencies
import { useEffect, useState } from 'react';
import { getRowId, icon } from '../../../js/util';
import { getRequest } from '../../../js/axios';

// Components
import Modal from '../../components/modal';
import Table from '../../components/table';
import IconButton from '../../components/iconbutton';
import { GET_RENEWAL } from '../../../constants/api-route';
import { getCookie } from '../../../js/cookie';
import { ADMIN_TYPE } from '../../../constants/cookie';
import { Link, useNavigate } from 'react-router-dom';
import { CONSUL } from '../../../constants';

let AdminConsulRenewals = ({ title, description }) => {
  // Variable
  const [id, setId] = useState(null);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [renewals, setRenewals] = useState([]);
  const navigate = useNavigate();
  const columns = [
    {field: 'chosenTimeSlot', header: 'TIMESTAMP'},
    {field: 'idNo', header: 'APPLICATION NO.'},
    {field: 'name', header: 'FULL NAME'}
  ];

  // Methods
  const actionColumnBody = (data) => {
    return (
    <div>
      <span onClick={() => {} } >
        <Link class="far fa-eye js-icon-button" to={`/admin/viewapplicant/${data.userID}`}/>
      </span>
      <IconButton icon={true} addtlButtonClass={`ms-4 icon row-id-${data.renewalID}`} addtlIconClass="far fa-pen-to-square js-icon-button" onClickAction={(event) => updateRenewalStatus(event)}/>
    </div>);
  }

  const getRenewals = (data) => {
    let renewalData = [];
    data.forEach((obj)=> {
      let objrenewal = {
        userID: obj.userID,
        renewalID: obj.renewalID,
        chosenTimeSlot: obj.chosenTimeSlot,
        idNo: obj.idNo,
        name: `${obj.firstName} ${obj.lastName}`,
        nameContactPerson: obj.nameContactPerson,
        status: obj.status
      }
      renewalData.push(objrenewal);
    })
    setRenewals(renewalData);
  }

  const updateRenewalStatus = (event) => {
    setUpdateVisible(true);
    getRowId(event, (id) => {
      setId(id)
    });
  }

  // Hooks
  // componentDidMount
  useEffect(() => {
    icon();
  });

  // componentDidUpdate
  useEffect(() => {
    getRequest(GET_RENEWAL, getRenewals);
    // (getCookie(ADMIN_TYPE) !== CONSUL && navigate('/error'));
  }, []);


  // Template
  return (
    <div class="overflow-y-auto w-100 h-100 px-3 py-5 p-lg-5">
      <div class="m-0 p-0 w-100">
        <h2 class="fs-md-24 fs-24 fw-bold">{ title }</h2>
        <p class="fs-md-14 fs-12 mb-5">{ description }</p>
      </div>
      <Table class="table w-100" columns={columns} data={renewals} actionContent={actionColumnBody} status={true} statusID='status'/>
      <Modal displayModal={updateVisible} onHide={() => setUpdateVisible(false)} id={id} details={ {applicants: false} } superadmin={true} changeapplicationstat={true}/>
    </div>
  );
    
}

export default AdminConsulRenewals;