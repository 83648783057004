// Dependencies

import { useEffect, useState } from 'react';
import { getRowId, icon } from '../../../js/util';
import { getRequest } from '../../../js/axios';

// Components
import IconButton from '../../components/iconbutton';
import Modal from '../../components/modal';
import Table from '../../components/table';
import { GET_USERS } from '../../../constants/api-route';
import { MARKETER, RETIREE, SUPERADMIN, ADMIN_CONSUL, ADMIN_EVALUATOR } from '../../../constants/api-table-user';

/**
 * Superadmin Rules
 * 
 * @returns template
 * 
 * @author Nicele Reyes
 * @since 04/08/2024 
 */
let SuperadminAccountMgmt = ({title, description}) => {
  // Variable
  const [visible, setVisible] = useState(false);
  const [account, setAccounts] = useState([]);
  const [id, setId] = useState(null);
  const columns = [
    {field: 'name', header: 'FULL NAME'},
    {field: 'email', header: 'EMAIL ADDRESS'},
    {field: 'mobileNumber', header: 'CONTACT NUMBER'},
    {field: 'usertype', header: 'USER TYPE'}
  ];
  // Methods
  const actionColumnBody = (data) => {
    // return (<IconButton icon={true} addtlButtonClass={`icon row-id-${data.userID}`} addtlIconClass="far fa-pen-to-square js-icon-button" onClickAction={(event) => edit(event)}/>);
    return (<IconButton icon={true} addtlButtonClass={`icon row-id-${data.userID}`} addtlIconClass="far fa-pen-to-square js-icon-button" onClickAction={(event) => edit(event)}/>);
  }

  const formatData = (data) => {
    let accounts = [];
    let usertypeFormat = {
      [RETIREE]        : 'Retiree',
      [MARKETER]       : 'Marketer',
      [ADMIN_CONSUL]   : 'Admin - Consul',
      [ADMIN_EVALUATOR]: 'Admin - Evaluator',
      [SUPERADMIN]     : 'Super Admin'
    }

    data.forEach((accountDetails) => {
      let details = {
        userID: accountDetails.userID,
        name: accountDetails.name,
        email: accountDetails.email,
        mobileNumber: accountDetails.mobileNumber,
        usertype: usertypeFormat[accountDetails.usertype]
      }

      accounts.push(details);
    })

    setAccounts(accounts);
  };

  const edit = (event) => {
    setVisible(true);
    getRowId(event, (id) => {
      setId(id)
    });
  }

   // Hooks
  // componentDidMount
  useEffect(() => {
    icon();
  });

  // componentDidUpdate
  useEffect(() => {
    getRequest(GET_USERS, formatData);
  }, []);

  // Template
  return (
    <div class="overflow-y-auto w-100 h-100 px-3 py-5 p-lg-5">
      <div class="m-0 p-0 w-100">
        <h2 class="fs-md-24 fs-24 fw-bold">{ title }</h2>
        <p class="fs-md-14 fs-12 mb-5">{ description }</p>
      </div>
      <Table class="table w-100" columns={columns} data={account} actionContent={actionColumnBody}/>
      <Modal displayModal={visible} onHide={() => setVisible(false)} superadmin={true} changeaccounttype={true} id={id} idName="id"/>
    </div>
  );
    
}

export default SuperadminAccountMgmt;