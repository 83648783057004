// Dependencies
import { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';

let Table = ({ idName='', columns, data, status=false, statusID='', actionContent='' }) => {
  // Variable
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  // Methods
  const renderHeader = () => {
    return (
        <div className="d-flex justify-content-end">
            <span>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Type to search" />
                <i class="fa fa-search"/>
            </span>
        </div>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const initFilters = () => {
    setFilters({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    setGlobalFilterValue('');
  };

  const statusContent = (data) => {
    return <Tag value={data[statusID]} severity={`${getSeverity(data[statusID])}`}/>
  }
  
  const getSeverity = (status) => {
    switch (status) {
      case 'APPROVED':
        return 'success';
      case 'ENDORSED':
        return 'success';
      case 'PROCESSING':
        return 'warning';
      case 'DENIED':
        return 'danger';
      default:
        return null
    }
  }
  
  // Hooks
  useEffect(() => {
    initFilters();
  }, []);

  // Template
  return (
    <DataTable value={data}
      tableStyle={{ minWidth: '50rem' }}
      removableSort
      paginator
      rows={10}
      rowsPerPageOptions={[10, 25, 50, 100]}
      filters={filters}
      header={renderHeader()}
      emptyMessage="NO DATA AVAILABLE IN TABLE">
      {columns.map((columnData, i) => (
        <Column field={columnData.field} header={columnData.header} sortable/>
      ))}
      {status && <Column field="status" header="STATUS" body={statusContent}/>}
      <Column field={idName} header="ACTION" body={actionContent}/>
    </DataTable>
  );
    
}

export default Table;