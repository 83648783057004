import { Outlet } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom';
import { verifySession } from '../../js/util';
import { useEffect } from "react";

import InvalidAgeModal from "./invalidAgeModal";

function RetireeApplicationTemplate({ setIsAgeModal }) {
  
  const location = useLocation();
  const navigate = useNavigate();

  // CHANGE THIS IMPLEMENTATION GET AGE FROM THE USERID
  const age = 50

  useEffect(() => {
    verifySession(location.pathname, (bSession) => {
      bSession && navigate('/error');
    });

    if (age < 50) {
      setIsAgeModal(true);
    }
  }, []);
  
  return (
    <div>

        {/* <Header /> */}

        <Outlet />

        {/* <Footer /> */}

    </div>
  )
}

export default RetireeApplicationTemplate