import axios from 'axios';
import { API_URL } from '../constants';
import { showErrorToast } from './toast';

export const getRequest = (route, callbackResult, params={}) => {
  Promise.all([
    axios.get(`${API_URL}${route}`, {
      params: params,
      headers: {
        'Access-Control-Allow-Origin': true
      }
    })
  ]).then(objResult => {
    callbackResult(objResult[0].data);
  }).catch((err) => {
    console.log(err);
    showErrorToast('Something Went Wrong');
  });
};
  
export const postRequest = (route, body, callbackResult, params={}) => {
  Promise.all([
    axios.post(`${API_URL}${route}`, body, {
      params: params,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  ]).then(objResult => {
    callbackResult(objResult[0].data);
  }).catch((err) => {
    console.log(err);
    showErrorToast('Something Went Wrong');
  });
}
  
export const putRequest = (route, body, callbackResult) => {
  Promise.all([
    axios.put(`${API_URL}${route}`, body, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  ]).then(objResult => {
    callbackResult(objResult[0].data);
  }).catch((err) => {
    console.log(err);
    showErrorToast('Something Went Wrong');
  });
}