// Dependencies
import { useEffect, useState } from 'react';
import { getRowId, icon } from '../../../js/util';
import { getRequest } from '../../../js/axios';

// Components
import Button from '../../components/button';
import Modal from '../../components/modal';
import Table from '../../components/table';
import { GET_RULES } from '../../../constants/api-route';

/**
 * Superadmin Rules
 * 
 * @returns template
 * 
 * @author Nicele Reyes
 * @since 04/08/2024 
 */
let SuperadminRules = ({title, description}) => {
  // Variable
  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [rules, setRules] = useState([]);
  const [details, setDetails] = useState([]);
  const [id, setId] = useState(null);
  const columns = [
    {field: 'title', header: 'TITLE'},
    {field: 'description', header: 'DESCRIPTION'}
  ];
  

  // Methods
  const actionColumnBody = (data) => {
    return (<button class={`far fa-pen-to-square js-icon-button row-id-${data.ruleID}`} type="button" onClick={(event) => edit(event)}></button>);
  }

  const edit = (event) => {
    getRowId(event, (id) => {
      setId(id);
      setDetails(rules.find((rule) => rule.ruleID === +id));
      setEditVisible(true);
    });
  }

  // Hooks
  // componentDidMount
  useEffect(() => {
    icon();
  });

  // componentDidUpdate
  useEffect(() => {
    getRequest(GET_RULES, setRules);
  }, [setRules]);


  // Template
  return (
    <div class="overflow-y-auto w-100 h-100 px-3 py-5 p-lg-5">
      <div class="m-0 p-0 w-100">
        <h2 class="fs-md-24 fs-24 fw-bold">{ title }</h2>
        <p class="fs-md-14 fs-12 mb-5">{ description }</p>
      </div>
      <Button text="Add Rules" icon={true} addtlButtonClass="btn button--section fs-14" addtlIconClass="fas fa-plus" onClickAction={() => setAddVisible(true)}/>
      <Table class="table w-100" columns={columns} data={rules} actionContent={actionColumnBody}/>
      <Modal displayModal={addVisible} onHide={() => setAddVisible(false)} rules={true} add={true}/>
      <Modal displayModal={editVisible} onHide={() => setEditVisible(false)} rules={true} edit={true} id={id} idName="ruleID" details={details}/>
    </div>
  );
    
}

export default SuperadminRules;