// Dependencies
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from "../../../components/button";
import { Button as StepperButton } from 'primereact/button';
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Divider } from 'primereact/divider';
// import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

import Calendar from './calendar';

import StepTimelineRenewal from './step_timeline_renewal'



let RenewalForm = () => {
  // Variable
  const stepperRef = useRef(null);

  const [marketerField, setMarketerField] = useState("hidden");
  const [isMarketerOpen, setIsMarketerOpen] = useState(false);

  const [isVisible, setIsVisible] = useState(false);



  const [dates, setDates] = useState(new Date('2024-02-02'));

  // FORMS HIDDEN STATES
  const [form1, setForm1] = useState("");
  const [form2, setForm2] = useState("hidden");
  const [form3, setForm3] = useState("hidden");
  const [form4, setForm4] = useState("hidden");
  const [form5, setForm5] = useState("hidden");
  const [form6, setForm6] = useState("hidden");
  const [form7, setForm7] = useState("hidden");
  const [form8, setForm8] = useState("hidden");
  const [form9, setForm9] = useState("hidden");

  const [isYes, setIsYes] = useState("hidden");
  const [isNo, setIsNo] = useState("hidden");


  const appointment_values = ['7:00AM - 8:00AM', '8:00AM - 9:00AM', '9:00AM - 10:00AM', '10:00AM - 11:00AM', '11:00AM - 12:00NN', '12:00NN - 1:00PM', '1:00PM - 2:00PM', '2:00PM - 3:00PM', '3:00PM - 4:00PM']

  const [step, setStep] = useState(1);
  
    // ##### FORM VALUES TO PASS IN API #####
    // applicationID,  userID, applicationForm, status, type, isMarketerNeeded, marketerID, visaType, srrvOption, lastName, firstName, alias, religion, gender, dateOfBirth, placeOfBirth, nationality, idNo, civilStatus, heightCM, weightKG, passportNo, placeOfISsue, dateOfIssue, validUntil, homeCountryAddress, telephoneNo, faxNo, mobileNo, email, primaryAddressPH, secondaryAddressPH, nameOfFather, ageOfFather, nameOfMother, ageOfMother, nameContactPerson, contactPersonNum, contactPersonNationality, contactPersonRelationship, contactPersonAddress, dateOfArrivalPH, touristExpirationDate, entryVisaPH, isPHVisited, visaTypeTwo, visaOthers, periodOfStayOne, periodOfStayOneAddress, periodOfStayTwo, periodOfStayTwoAddress, periodOfStayThree, periodOfStayThreeAddress, periodOfStayFour, periodOfStayFourAddress, educationalAttainmentOne, educationalAttainmentTwo, educationalAttainmentThree, schoolLocationOne, schoolLocationTwo, schoolLocationThree, schoolOneFrom, schoolTwoFrom, schoolThreeFrom, nameOfPresentCompany, addressOfPresentCompany, presentCompanyContactNum, futureActions, employmentCompanyNameAddressOne, employmentCompanyNameAddressTwo, employmentCompanyNameAddressThree, employmentCompanyJobTitleOne, employmentCompanyJobTitleTwo, employmentCompanyJobTitleThree, employmentCompanyOneFromTo, employmentCompanyTwoFromTo, employmentCompanyThreeFromTo, applicantSignature, chosenTimeSlot, vatFee, applicationFee, appointmentFee, serviceFee, submissionDate



  // Methods

const [counter, setCounter] = useState(0);

// useEffect(() => {
//   const interval = setInterval(() => {
//     setCounter(counter => counter + 1);

//     if (counter % 2 === 0) {
//       setIsVisible(true);
//     } else {
//       setIsVisible(false);
//     }
//   }, 6000); // 2000 milliseconds = 2 seconds

//   // Clear the interval when the component is unmounted
//   return () => clearInterval(interval);
// }, []);

  useEffect(() => {
    handleStepChange(step);
  }, [step]);

  // Methods
function handleStepChange(step) {
  switch (step) {
    case 1:
      setForm1("");
      setForm2("hidden");
      setForm3("hidden");
      setForm4("hidden");
      setForm5("hidden");
      setForm6("hidden");
      setForm7("hidden");
      setForm8("hidden");
      setForm9("hidden");
      break;
    case 2:
      setForm1("hidden");
      setForm2("");
      setForm3("hidden");
      setForm4("hidden");
      setForm5("hidden");
      setForm6("hidden");
      setForm7("hidden");
      setForm8("hidden");
      setForm9("hidden");
      break;
    case 3:
      setForm1("hidden");
      setForm2("hidden");
      setForm3("");
      setForm4("hidden");
      setForm5("hidden");
      setForm6("hidden");
      setForm7("hidden");
      setForm8("hidden");
      setForm9("hidden");
      break;
    case 4:
      setForm1("hidden");
      setForm2("hidden");
      setForm3("hidden");
      setForm4("");
      setForm5("hidden");
      setForm6("hidden");
      setForm7("hidden");
      setForm8("hidden");
      setForm9("hidden");
      break;
    case 5:
      setForm1("hidden");
      setForm2("hidden");
      setForm3("hidden");
      setForm4("hidden");
      setForm5("");
      setForm6("hidden");
      setForm7("hidden");
      setForm8("hidden");
      setForm9("hidden");
      break;
    case 6:
      setForm1("hidden");
      setForm2("hidden");
      setForm3("hidden");
      setForm4("hidden");
      setForm5("hidden");
      setForm6("");
      setForm7("hidden");
      setForm8("hidden");
      setForm9("hidden");
      break;
    case 7:
      setForm1("hidden");
      setForm2("hidden");
      setForm3("hidden");
      setForm4("hidden");
      setForm5("hidden");
      setForm6("hidden");
      setForm7("");
      setForm8("hidden");
      setForm9("hidden");
      break;
    case 8:
      setForm1("hidden");
      setForm2("hidden");
      setForm3("hidden");
      setForm4("hidden");
      setForm5("hidden");
      setForm6("hidden");
      setForm7("hidden");
      setForm8("");
      setForm9("hidden");
      break;
    // case 9:
    //   setForm1("hidden");
    //   setForm2("hidden");
    //   setForm3("hidden");
    //   setForm4("hidden");
    //   setForm5("hidden");
    //   setForm6("hidden");
    //   setForm7("hidden");
    //   setForm8("hidden");
    //   setForm9("");
    //   break;

    default:
      break;
  }
}

const handleGoBack = () => {
  if (step > 1) {
    setStep(prevStep => prevStep - 1);
  }
};

const navigate = useNavigate();

const handleGoNext = () => {
  if (step === 8) {
    navigate("/retiree/application/renewal/renewal_confirmation");
  } else {
    if (step < 8) {
      setStep(prevStep => prevStep + 1);
    }
  }
};

const handleDateChange = (e) => {
    setDates(e.value);
    const formattedDate = e.value.toLocaleDateString('en-CA');
    console.log(formattedDate);
}
  

  // Template
  return (
    <div class="retiree-section-container min-screen">
      <div class="banner retiree-form">
        <div class="container-fluid">

            <StepTimelineRenewal step={step}/>

        </div>
      </div>


      {/* container-fluid retiree-section-content*/}

      {/* FORM GUIDE:
      1. Form 1 is shown by default.
      2. Other Form Components are hidden by default.
      3. Hidden Input forms are displayed on top of the FORM # comment */}

      {/* FORM COMPONENT */}
      {/* INSERT API POST_APPLICATION HERE */}
      <form action="" method="post">

        {/* FORM 1 */}
        <div className={`${form1} flex flex-col gap-9 items-center justify-center min-h-[36vh] py-16`}>

          <p class="fs-24 fw-bold text-center">Do you prefer to be assisted by a marketer?</p>

          <div class="flex flex-row items-center gap-9 justify-center blue-card-container">
            <button type="button" className="button button--section flex flex-row items-center text-uppercase justify-start gap-6 text-white font-bold bg-[#005DB3] fs-18 text-start border-radius-20 button--application transition hover:bg-blue-600" onClick={() => setMarketerField("")}>
              <img src="img/icon-yes.png" alt="Yes" />
              Yes, Please
            </button>
            <button type="button" className="button button--section flex flex-row items-center text-uppercase justify-start gap-6 text-white font-bold bg-[#005DB3] fs-18 text-start border-radius-20 button--application transition hover:bg-blue-600" onClick={() => setMarketerField("hidden")}>
              <img src="img/icon-dislike.png" alt="No" />
              No
            </button>
          </div>

          {/* DROPDOWN */}
          <div className={`${marketerField}`}>
                {/* <button id="dropdownDefaultButton" data-dropdown-toggle="dropdown" class={`text-black fs-18 font-bold bg-white focus:ring-blue-300 rounded-lg text-sm px-5 py-2.5 border-solid border-gray-500 border-2 text-center flex flex-row items-center justify-center h-[7vh] w-[15vw]`} type="button" onClick={() => setIsMarketerOpen(!isMarketerOpen)}>
                  
                  Choose Marketer
                
                  <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                  </svg>

                </button> */}

            <div class="inline-block relative w-64">
              {/* CHANGE THE VALUE AFTER CONNECTING TO THE DATABASE  */}
              <select name="marketerID" class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline h-[7vh] fs-18 font-bold">
                <option selected disabled>Select Marketer</option>
                <option>Keanu Reeves</option>
                <option>Daenerys</option>
                <option>Walter White</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>
          </div>

        </div>

        {/* FORM 2 */}

        <input type="text" name="visaType" value="SMILE" className="hidden"></input>

        <div className={`${form2} flex flex-col gap-9 items-center justify-center min-h-[36vh] py-16`}>

          <p class="fs-24 fw-bold text-center">Choose your visa type</p>

            <div class="flex flex-col items-center gap-9 justify-center blue-card-container">

              <div class="flex flex-row gap-9 w-full justify-center">
                <button type="button" className="flex flex-row items-center justify-start gap-6 text-white font-bold bg-[#005DB3] fs-18 text-start border-radius-20 button--application transition hover:bg-blue-600 flex-1">
                  <img src="img/icon-vt-smile.png" alt="Yes" />
                  SMILE
                </button>

                <button type="button" className="flex flex-row items-center justify-start gap-6 text-white font-bold bg-[#005DB3] fs-18 text-start border-radius-20 button--application transition hover:bg-blue-600 flex-1">
                  <img src="img/icon-vt-classic.png" alt="No" />
                  CLASSIC
                </button>
              </div>

              <div class="flex flex-row gap-9 w-full justify-center">
                <button type="button" className="flex flex-row items-center justify-start gap-6 text-white font-bold bg-[#005DB3] fs-18 text-start border-radius-20 button--application transition hover:bg-blue-600 flex-1">
                  <img src="img/icon-vt-humantouch.png" alt="Yes" />
                  HUMAN TOUCH
                </button>

                <button type="button" className="flex flex-row items-center justify-start gap-6 text-white font-bold bg-[#005DB3] fs-18 text-start border-radius-20 button--application transition hover:bg-blue-600 flex-1">
                  <img src="img/icon-vt-courtesy.png" alt="No" />
                  COURTESY
                </button>
              </div>

            </div>

        </div>


        {/* FORM 3 */}

        <div className={`${form3} flex flex-col gap-9 items-center justify-center min-h-[36vh] py-16`}>

          <p class="fs-24 fw-bold text-center">Principal Retiree</p>
          <div class="container">
            <h5 class="fw-bold">Basic Information</h5>
            <div class="row mb-1 mt-4 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">FULL NAME</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">ALIAS (AKA)</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">RELIGION</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <div class="dropdown">
                  <div class="dropdown-select position-relative">
                    <select class="form__input w-100 px-3 fs-md-14 border--gray border border-2 rounded-2 justify-content-between align-items-center d-flex" id="change-account-type" list="account_type" name="account_type" required="">
                      <option value="" disabled="" selected="">Select gender</option>
                      <option>Female</option>
                      <option>Male</option>
                    </select>
                  </div>
                  <label class="text-uppercase fw-semibold fs-14 bg-white px-1 top-0 position-absolute" for="change-account-type">GENDER</label>
                </div>
              </div>
            </div>
            <div class="row mb-1 mt-2 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">DATE OF BIRTH</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">PLACE OF BIRTH</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">NATIONALITY</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">ID NO.</label>
              </div>
            </div>
            <div class="row mb-1 mt-2 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <div class="dropdown">
                  <div class="dropdown-select position-relative">
                    <select class="form__input w-100 px-3 fs-md-14 border--gray border border-2 rounded-2 justify-content-between align-items-center d-flex" id="change-account-type" list="account_type" name="account_type" required="">
                      <option value="" disabled="" selected="">Select civil status</option>
                      <option>Single</option>
                      <option>Married</option>
                      <option>Divorced</option>
                      <option>Widowed</option>
                    </select>
                  </div>
                  <label class="text-uppercase fw-semibold fs-14 bg-white px-1 top-0 position-absolute" for="change-account-type">CIVIL STATUS</label>
                </div>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">HEIGHT (IN FT.)</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">WEIGHT (IN LBS.)</label>
              </div>
            </div>
          </div>
          <Divider />
          <div class="container">
            <h5 class="fw-bold">Passport Information</h5>
            <div class="row mb-1 mt-4 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">PASSPORT NO.</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">PLACE OF ISSUE</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">DATE OF ISSUE</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">VALID UNTIL</label>
              </div>
            </div>
          </div>
          <Divider />
          <div class="container">
            <h5 class="fw-bold">Address Information</h5>
            <div class="row mb-1 mt-4 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-12">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">HOME COUNTRY ADDRESS</label>
              </div>
            </div>
            <div class="row mb-1 mt-2 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">TELEPHONE NO.</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">FAX NO.</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">MOBILE NO.</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">E-MAIL</label>
              </div>
            </div>
            <div class="row mb-1 mt-2 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-12">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">PRIMARY ADDRESS IN THE PHILIPPINES</label>
              </div>
            </div>
            <div class="row mb-1 mt-2 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-12">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">SECONDARY ADDRESS IN THE PHILIPPINES</label>
              </div>
            </div>
            <div class="row mb-1 mt-2 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">TELEPHONE NO.</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">FAX NO.</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">MOBILE NO.</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">E-MAIL</label>
              </div>
            </div>
          </div>
        </div>


        {/* FORM 4 */}
        <div className={`${form4} flex flex-col gap-9 items-center justify-center min-h-[36vh] py-16`}>
          <p class="fs-24 fw-bold text-center">Family Member</p>
          <div class="container">
            <h5 class="fw-bold">Spouse's Information</h5>
            <div class="row mb-1 mt-2 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">FULL NAME</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">DATE OF BIRTH</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-1">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">AGE</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-2">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">ID NO.</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <div class="fs-14 fw-bold">INCLUDED IN APPLICATION?</div>
                <div class="d-flex checkbox-options yes-no-option">
                  <div class="form-check p-0">
                    <input class="form-check-input" type="checkbox" value="" checked />
                    <label class="form-check-label" for="flexCheckDefault">
                      Yes
                    </label>
                  </div>
                  <div class="form-check p-0">
                    <input class="form-check-input" type="checkbox" value="" />
                    <label class="form-check-label" for="flexCheckChecked">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="fw-bold">Child's Information</h5>
              <button class="button button--section col-md-2 bg--blue rounded-2 text-white fw-bold d-flex align-items-center justify-content-center text-uppercase fs-14">
                <span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                <path d="M8.125 1.4375C8.125 1.18886 8.02623 0.950403 7.85041 0.774587C7.6746 0.598772 7.43614 0.5 7.1875 0.5C6.93886 0.5 6.7004 0.598772 6.52459 0.774587C6.34877 0.950403 6.25 1.18886 6.25 1.4375V6.75H0.9375C0.68886 6.75 0.450403 6.84877 0.274587 7.02459C0.0987719 7.2004 0 7.43886 0 7.6875C0 7.93614 0.0987719 8.1746 0.274587 8.35041C0.450403 8.52623 0.68886 8.625 0.9375 8.625H6.25V13.9375C6.25 14.1861 6.34877 14.4246 6.52459 14.6004C6.7004 14.7762 6.93886 14.875 7.1875 14.875C7.43614 14.875 7.6746 14.7762 7.85041 14.6004C8.02623 14.4246 8.125 14.1861 8.125 13.9375V8.625H13.4375C13.6861 8.625 13.9246 8.52623 14.1004 8.35041C14.2762 8.1746 14.375 7.93614 14.375 7.6875C14.375 7.43886 14.2762 7.2004 14.1004 7.02459C13.9246 6.84877 13.6861 6.75 13.4375 6.75H8.125V1.4375Z" fill="white"/>
                </svg></span>&nbsp;&nbsp;
                ADD CHILD
              </button>
            </div>
            <div class="row mb-1 mt-2 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">FULL NAME</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">DATE OF BIRTH</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-1">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">AGE</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-2">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">ID NO.</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <div class="fs-14 fw-bold">INCLUDED IN APPLICATION?</div>
                <div class="d-flex checkbox-options yes-no-option">
                  <div class="form-check p-0">
                    <input class="form-check-input" type="checkbox" value="" checked />
                    <label class="form-check-label" for="flexCheckDefault">
                      Yes
                    </label>
                  </div>
                  <div class="form-check p-0">
                    <input class="form-check-input" type="checkbox" value="" />
                    <label class="form-check-label" for="flexCheckChecked">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* FORM 5: OLD PARENTS */}
        {/* <div className={`${form5} flex flex-col gap-9 items-center justify-center min-h-[36vh] py-16`}>
          <p class="fs-24 fw-bold text-center">Parent</p>
          <div class="container">
            <h5 class="fw-bold">Father's Information</h5>
            <div class="row mb-1 mt-4 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-10">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">FULL NAME</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-2">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">AGE</label>
              </div>
            </div>
          </div>
          <Divider />
          <div class="container">
            <h5 class="fw-bold">Mother's Information</h5>
            <div class="row mb-1 mt-4 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-10">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">FULL NAME</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-2">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">AGE</label>
              </div>
            </div>
          </div>
          <Divider />
          <div class="container">
            <h5 class="fw-bold">Emergency Contact</h5>
            <div class="row mb-1 mt-4 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-10">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">FULL NAME</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-2">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">AGE</label>
              </div>
            </div>
          </div>
        </div> */}


        {/* FORM 6 */}
        <div className={`${form5} flex flex-col gap-9 items-center justify-center min-h-[36vh] py-16`}>
          <p class="fs-24 fw-bold text-center">Education & Career</p>
          <div class="container">
            <div class="row mb-1 mt-4 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">COLLEGE/UNIVERSITY</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-6">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">SCHOOL AND LOCATION</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">FROM/TO (MM/YYYY ~ MM/YYYY)</label>
                <div className="card flex justify-content-center">
                  {/* <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection /> */}
                </div>
              </div>
            </div>
            <div class="row mb-1 mt-2 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">HIGHSCHOOL</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-6">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">SCHOOL AND LOCATION</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">FROM/TO (MM/YYYY ~ MM/YYYY)</label>
                <div className="card flex justify-content-center">
                  {/* <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection /> */}
                </div>
              </div>
            </div>
            <div class="row mb-1 mt-2 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">ELEMENTARY</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-6">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">SCHOOL AND LOCATION</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">FROM/TO (MM/YYYY ~ MM/YYYY)</label>
                <div className="card flex justify-content-center">
                  {/* <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection /> */}
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div class="container">
            <h5 class="fw-bold">Present Company / Business</h5>
            <div class="row mb-1 mt-4 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-10">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">NAME</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-2">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">CONTACT NO.</label>
              </div>
            </div>
          </div>
          <Divider />
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="fw-bold">Employment in the Last Three (3) Years</h5>
              <button class="button button--section col-md-2 bg--blue rounded-2 text-white fw-bold d-flex align-items-center justify-content-center text-uppercase fs-14">
                <span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                <path d="M8.125 1.4375C8.125 1.18886 8.02623 0.950403 7.85041 0.774587C7.6746 0.598772 7.43614 0.5 7.1875 0.5C6.93886 0.5 6.7004 0.598772 6.52459 0.774587C6.34877 0.950403 6.25 1.18886 6.25 1.4375V6.75H0.9375C0.68886 6.75 0.450403 6.84877 0.274587 7.02459C0.0987719 7.2004 0 7.43886 0 7.6875C0 7.93614 0.0987719 8.1746 0.274587 8.35041C0.450403 8.52623 0.68886 8.625 0.9375 8.625H6.25V13.9375C6.25 14.1861 6.34877 14.4246 6.52459 14.6004C6.7004 14.7762 6.93886 14.875 7.1875 14.875C7.43614 14.875 7.6746 14.7762 7.85041 14.6004C8.02623 14.4246 8.125 14.1861 8.125 13.9375V8.625H13.4375C13.6861 8.625 13.9246 8.52623 14.1004 8.35041C14.2762 8.1746 14.375 7.93614 14.375 7.6875C14.375 7.43886 14.2762 7.2004 14.1004 7.02459C13.9246 6.84877 13.6861 6.75 13.4375 6.75H8.125V1.4375Z" fill="white"/>
                </svg></span>&nbsp;&nbsp;
                ADD EMPLOYMENT
              </button>
            </div>
            <div class="row mb-1 mt-4 mb-sm-2">
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">JOB TITLE</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-6">
                <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="first-name" type="text" placeholder="" autoComplete="" />
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">COMPANY NAME AND ADDRESS</label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-3">
                <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"for="first-name">FROM/TO (MM/YYYY ~ MM/YYYY)</label>
                <div className="card flex justify-content-center">
                  {/* <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection /> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FORM 7 */}


        {/* FORM 8 */}

        <div className={`${form7} flex flex-col gap-9 items-center justify-center min-h-[36vh] py-16`}>

          <div className="flex flex-row justify-center items-center gap-20">

              <div className="w-auto h-auto">
                {console.log('State changed, component should re-render')}
                {/* {isVisible && <Calendar value={new Date()} onChange={(e) => console.log(e.value)} inline showWeek />} */}
                <Calendar />
              </div>

              {/* APPOINTMENT SCHEDULES*/}
              <div>
                <div className="flex flex-col gap-2 justify-center items-center w-[25vw]" >
                    {appointment_values.map((value, index) => {
                      if (index % 2 === 0) {
                        const isSingleButton = !appointment_values[index + 1];
                        return (
                          <div className={`flex flex-row items-center gap-9  ${isSingleButton ? 'justify-start' : 'justify-center'} w-[22vw]`} onClick={() => setIsVisible(!isVisible)}>
                            <button type="button" className="flex flex-row items-center justify-center gap-6 text-gray-900 font-bold bg-gray-100 fs-18 text-start border-solid border-2 border-gray-200 rounded-4 transition hover:bg-[#005DB3] hover:text-white h-[7vh] min-w-[10vw]">
                              {appointment_values[index]}
                            </button>
                            {!isSingleButton && (
                              <button type="button" className="flex flex-row items-center justify-center gap-6 text-gray-900 font-bold bg-gray-100 fs-18 text-start border-solid border-2 border-gray-200 rounded-4 transition hover:bg-[#005DB3] hover:text-white h-[7vh] min-w-[10vw]">
                                {appointment_values[index + 1]}
                              </button>
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}
                </div>

              </div>

          </div>

        </div>

        {/* FORM 9 */}


        {/* THANKS */}

      </form>

      
      {/* BACK AND NEXT BUTTONS */}
      <div className="min-h-[10vh] w-[100vw] flex flex-row gap-6 justify-end items-center pr-48 bg-white pb-12 pt-12 rounded-top-5 shadow-lg">
        <button class="button button--section fs-14 border border-2 rounded-2 fw-bold d-flex align-items-center justify-content-center border--blue fc-blue" onClick={handleGoBack}>GO BACK</button>
        <button class="button button--section bg--blue rounded-2 text-white fw-bold d-flex align-items-center justify-content-center text-uppercase fs-14" onClick={handleGoNext}>NEXT STEP</button>
      </div>

    </div>
  );
}

export default RenewalForm