import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";

function InvalidAgeModal({ isAgeModal, setIsAgeModal }) {

    const handleSubmit = (event) => {

        navigate("/retiree/application/main");

        event.preventDefault();
        // console.log(`Name: ${Name}, Birthdate: ${Birthdate}`);
        console.log(setIsAgeModal)
        setIsAgeModal(false);
        // You can save the values here
    };

  const navigate = useNavigate();

  return (

    <div>

      {/* Overlay Modal */}
      {isAgeModal && (

      <div className="fixed  h-full w-full flex flex-col justify-center items-center backdrop-blur-sm backdrop-filter overflow-hidden bg-opacity-60 z-50">

            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:h-[40vh] lg:w-[45vh] h-[85vw] w-[85vw] flex flex-col justify-center items-center z-500 rounded-3xl backdrop-blur-3xl bg-white backdrop-filter overflow-hidden bg-opacity-30 drop-shadow-lg text-white ">

            <div>
                <form onSubmit={handleSubmit} className="flex flex-col items-center gap-7">

                <div className="text-[20px] font-bold drop-shadow-lg  select-none text-black"> 
                    INVALID AGE MODAL
                </div>

                <div className="flex flex-row justify-center items-center gap-5">
                    <div className="p-2 text-[15px] font-bold drop-shadow-lg  text-black"> 
                    "insert positive message here ✅" 
                    </div>
                </div>

                <button type="submit" className="p-2 bg-[#005DB3] text-white rounded-md">Go Back</button>

                </form>
            </div>

            </div>

      </div>

      )}

    </div>
  )
}

export default InvalidAgeModal