export const SIGN_IN = '/sign_in';
export const SIGN_UP = '/sign_up';
export const OPT_VERIFY = '/otp_verify';
export const GET_USER = '/get_user';
export const GET_USERS = '/get_users';
export const GET_COUNTS = '/get_counts';
export const GET_RULES = '/get_rules';
export const GET_APPLICATION = '/get_application';
export const GET_RENEWAL = '/get_renewal';
export const UPDATE_USER_TYPE = '/update_user_type';
export const ADD_RULE = '/add_rule';
export const UPDATE_RULE = '/update_rule';
export const GET_SPECIFIC_APPLICATION = '/get_specific_application';
export const UPDATE_APPLICATION_STATUS = '/update_application_status';
export const UPDATE_RENEWAL_STATUS = '/update_renewal_status';