import $ from 'jquery';
import { getRequest } from './axios';
import { setCookie, removeCookie, getCookie } from './cookie';
import { ADMIN_TYPE, AUTH_TOKEN, COOKIES, SUPERADMIN_ID, USER_ID, USER_TYPE } from '../constants/cookie';
import { GET_USER } from '../constants/api-route';

export const setCookieDetails = (token, fnCallback) => {
  setCookie(AUTH_TOKEN, token);

  getRequest(GET_USER, (res) => {
    if (res.status === 200) {
      let userDetails = res.user;
      let userType = userDetails.usertype;
      setCookie(USER_TYPE, userType);
      setCookie(USER_ID, userDetails.userID);
      setCookie(SUPERADMIN_ID, userDetails.superAdminID);
      fnCallback();
    }
  }, { auth_token: token });
}

export const resetCookie = () => {
  COOKIES.forEach(id => {
    removeCookie(id);
  });
}

export const verifySession = (location, fnCallback) => {
  const usertype = getCookie(USER_TYPE);
  const routeParent = location.split('/').slice(1)[0];

  // fnCallback(usertype.toLowerCase() !== routeParent);
  fnCallback(false);
}

export const getRowId = (event, fnCallback) => {
  const ROW_ID = 'row-id-';
  const sClassName = event.currentTarget.className;
  const arrClasses = sClassName.split(' ');
  arrClasses.forEach((sClass) => {
    if (sClass.includes(ROW_ID)) {
      fnCallback(sClass.replace(ROW_ID, ''));
    }
  })
};

/**
 * Set input value of state from input field onchange
 * 
 * @param {*} fnCallback 
 * @param {*} event 
 */
export const setInputValue = (fnCallback, event) => {
  fnCallback(event.target.value);
}

export const icon = () => {
  try {
    let icons = document.querySelectorAll('.js-icon-button');

    icons.forEach(function(icon) {
      icon.addEventListener("mouseenter", function() {
        this.classList.add('fas');
        this.classList.remove('far');
      });

      icon.addEventListener("mouseleave", function() {
        this.classList.add('far');
        this.classList.remove('fas');
      });
    });
  } catch (error) {
    console.log(error);
  }
}

export const menu = () => {
  try {
    let menuCheckbox = document.getElementById("js-menu");
    let overlay = document.getElementById("js-overlay");
    let panel = document.getElementById("js-panel");
    let burgerLines = document.querySelectorAll(".header__burger-line");

    function toggleBurgerColor() {
      burgerLines.forEach(function (line) {
        line.classList.toggle("bg--blue", !menuCheckbox.checked);
        line.classList.toggle("bg-white", menuCheckbox.checked);
      });
    }

    function toggleClasses() {
      toggleBurgerColor();
      overlay.classList.toggle("overlay--active");
      panel.classList.toggle("panel--active");

      // Fade in/out the overlay
      if (overlay.classList.contains("overlay--active")) {
        overlay.style.transition = "opacity 1s";
        overlay.style.opacity = 1;
      } else {
        overlay.style.transition = "opacity 1s";
        overlay.style.opacity = 0;
      }

      document.body.classList.toggle("body--fixed");
    }

    // Add click event listener to menu checkbox
    menuCheckbox.addEventListener("click", function () {
      toggleClasses();
    });

    // Add click event listeners to overlay and sidebar links
    overlay.addEventListener("click", function () {
      toggleClasses();
      menuCheckbox.checked = false;
    });

    // Add click event listeners to sidebar links (replace .js-sidebar-link with your actual class)
    document.querySelectorAll(".js-sidebar-link").forEach(function (link) {
      link.addEventListener("click", function () {
        toggleClasses();
        menuCheckbox.checked = false;
      });
    });
  } catch (error) {
    console.log(error);
  }
}

export const showPassword = () => {
  try {
    $(() => {
      let passwordInput = $('#password');
      let lockIcon = $('#js-lock-password');

      passwordInput.on('focus', function () {
        $(this).addClass('js-input-focused');
        lockIcon.addClass('js-input-focused');
      });

      passwordInput.on('blur', function () {
        $(this).removeClass('js-input-focused');
        lockIcon.removeClass('js-input-focused');
      });

      lockIcon.on('mousedown', function (event) {
        // Check if the input is in focus before allowing the click event
        if (passwordInput.hasClass('js-input-focused')) {
          event.preventDefault(); // Prevent the default behavior to keep focus on the input

          if ($(this).hasClass('fa-lock')) {
            $(this).removeClass('fa-lock');
            $(this).addClass('fa-lock-open');
            passwordInput.attr('type', 'text');
          } else {
            $(this).removeClass('fa-lock-open');
            $(this).addClass('fa-lock');
            passwordInput.attr('type', 'password');
          }
        }
      });
    });
  }

  catch (error) {
    console.log(error);
  }
}

export const profile = () => {
  try {
    const profileInput = document.querySelector('.js-profile-input');
    const profileImage = document.querySelector('.js-profile-image');
    const profileRemoveBtn = document.querySelector('.js-profile-remove');

    if(profileInput) {
      // Add event listener for profile input change
      profileInput.addEventListener('change', function () {
        console.log("change profile")
        if (profileInput.files.length > 0) {
          const selectedImage = URL.createObjectURL(profileInput.files[0]);
          profileImage.src = selectedImage;
        }
      });
    }

    if(profileRemoveBtn) {
      // Add event listener for profile remove button
      profileRemoveBtn.addEventListener('click', function () {
        profileImage.src = '/img/avatar.png';
      });
    }

  } catch (error) {
    console.log(error);
  }
}