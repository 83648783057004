// SUPERADMIN
export const SUPERADMIN_DASHBOARD = '/superadmin/dashboard';
export const SUPERADMIN_ACCOUNT_MGMT = '/superadmin/accountmgmt';

// ADMIN
export const ADMIN_PROFILE = '/admin/profile';

// MARKETER
export const MARKETER_PROFILE = '/marketer/profile';

// RETIREE
export const RETIREE_HOME = '/retiree/home';