// Dependencies
import { useEffect } from 'react';
import Button from '../../components/button';

let OtherServicesModal = () => {
  // Variable

  // Methods

  // Hooks
  useEffect(() => {});

  // Template
  return (
    <div class="modal-content">
      <div class="modal-header border-0 align-items-start px-5 pt-5">
        <h1 class="modal-title fs-18 fw-bold">Checklist of Requirements As An Accredited Retirement Project Under SBMA/CDC Properties (Renewal)</h1>
      </div>
      <div class="modal-body border-0 p-5 w-100">
        <div class="row">
          <div class="col-md-6 form-check d-flex align-items-center p-3 bg--light-gray rounded-2">
            <input class="form-check-input border__width--2 border--blue float-none ms-0 mt-0 me-3" type="checkbox" value="" id="" />
            <label class="form-check-label fs-14 fw-medium col-10" for="defaultCheck1">
            Updated Application Form
            </label>
          </div>
          <div class="col-md-6 form-check d-flex align-items-center p-3 bg--light-gray rounded-2">
            <input class="form-check-input border__width--2 border--blue float-none ms-0 mt-0 me-3" type="checkbox" value="" id="" />
            <label class="form-check-label fs-14 fw-medium col-10" for="defaultCheck1">
            Latest Income Tax Return
            </label>
          </div>
          <div class="col-md-6 form-check d-flex align-items-center p-3 bg--light-gray rounded-2">
            <input class="form-check-input border__width--2 border--blue float-none ms-0 mt-0 me-3" type="checkbox" value="" id="" />
            <label class="form-check-label fs-14 fw-medium col-10" for="defaultCheck1">
            Updated Information Sheet
            </label>
          </div>
          <div class="col-md-6 form-check d-flex align-items-center p-3 bg--light-gray rounded-2">
            <input class="form-check-input border__width--2 border--blue float-none ms-0 mt-0 me-3" type="checkbox" value="" id="" />
            <label class="form-check-label fs-14 fw-medium col-10" for="defaultCheck1">
            Authorization Letter for Representative
            </label>
          </div>
          <div class="col-md-6 form-check d-flex align-items-center p-3 bg--light-gray rounded-2">
            <input class="form-check-input border__width--2 border--blue float-none ms-0 mt-0 me-3" type="checkbox" value="" id="" />
            <label class="form-check-label fs-14 fw-medium col-10" for="defaultCheck1">
            Latest Mayor’s Permit and/or updated SBMA Freeport 
            </label>
          </div>
          <div class="col-md-6 form-check d-flex align-items-center p-3 bg--light-gray rounded-2">
            <input class="form-check-input border__width--2 border--blue float-none ms-0 mt-0 me-3" type="checkbox" value="" id="" />
            <label class="form-check-label fs-14 fw-medium col-10" for="defaultCheck1">
            Development Corporation Certificate of Registration and Tax Exemption
            </label>
          </div>
          <div class="col-md-6 form-check d-flex align-items-center p-3 bg--light-gray rounded-2">
            <input class="form-check-input border__width--2 border--blue float-none ms-0 mt-0 me-3" type="checkbox" value="" id="" />
            <label class="form-check-label fs-14 fw-medium col-10" for="defaultCheck1">
            Administration / Clark Latest Audited Financial Statements
            </label>
          </div>
          <div class="col-md-6 form-check d-flex align-items-center p-3 bg--light-gray rounded-2">
            <input class="form-check-input border__width--2 border--blue float-none ms-0 mt-0 me-3" type="checkbox" value="" id="" />
            <label class="form-check-label fs-14 fw-medium col-10" for="defaultCheck1">
            Ocular Inspection Report (to be provided by PRA)
            </label>
          </div>
        </div>
        
        <div class="mt-5">
          <button class="button col-md-4 mx-auto col-12 bg--blue rounded-2 text-white fw-bold d-flex align-items-center justify-content-center text-uppercase fs-14" type="button" onClick={() => window.location.reload()} aria-label="Close">CLOSE</button>
        </div>
      </div>
    </div>
  );
    
}

export default OtherServicesModal;
{/* <div class="d-flex checkbox-options">
          <div class="form-check p-3 pt-3 pb-3 align-items-center">
            <input class="form-check-input mt-0" type="checkbox" value="" checked />
            <label class="form-check-label" for="flexCheckDefault">
            Updated Application Form
            </label>
          </div>
          <div class="form-check p-3 pt-3 pb-3 align-items-center">
            <input class="form-check-input mt-0" type="checkbox" value="" />
            <label class="form-check-label" for="flexCheckChecked">
              Latest Income Tax Return
            </label>
          </div>
          <div class="form-check p-3 pt-3 pb-3 align-items-center">
            <input class="form-check-input mt-0" type="checkbox" value=""  />
            <label class="form-check-label" for="flexCheckDefault">
            Updated Information Sheet
            </label>
          </div>
          <div class="form-check p-3 pt-3 pb-3 align-items-center bg-gray-200">
            <input class="form-check-input mt-0" type="checkbox" value="" />
            <label class="form-check-label" for="flexCheckChecked">
              Authorization Letter for Representative
            </label>
          </div>
          <div class="form-check p-3 pt-3 pb-3 align-items-center">
            <input class="form-check-input mt-0" type="checkbox" value=""  />
            <label class="form-check-label" for="flexCheckDefault">
            Latest Mayor’s Permit and/or updated SBMA Freeport 
            </label>
          </div>
          <div class="form-check p-3 pt-3 pb-3 align-items-center bg-gray-200">
            <input class="form-check-input mt-0" type="checkbox" value="" />
            <label class="form-check-label" for="flexCheckChecked">
            Development Corporation Certificate of Registration and Tax Exemption
            </label>
          </div>
          <div class="form-check p-3 pt-3 pb-3 align-items-center">
            <input class="form-check-input mt-0" type="checkbox" value=""  />
            <label class="form-check-label" for="flexCheckDefault">
            Administration / Clark Latest Audited Financial Statements; 
            </label>
          </div>
          <div class="form-check p-3 pt-3 pb-3 align-items-center bg-gray-200">
            <input class="form-check-input mt-0" type="checkbox" value="" />
            <label class="form-check-label" for="flexCheckChecked">
            Ocular Inspection Report (to be provided by PRA)
            </label>
          </div>
        </div> */}