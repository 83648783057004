// Dependencies
import { useEffect, useState, useRef } from "react";
import Button from "../../components/button";
import { getCookie } from "../../../js/cookie";

let RetireeProfile = () => {
  // Variable
  const [profileData, setProfileData] = useState({});
  const [fullname, setFullname] = useState("");
  const fNameRef = useRef(null);
  const lNameRef = useRef(null);
  const emailRef = useRef(null);
  const profileRef = useRef(null);
  const imageRef = useRef(null);
  // Methods
  // Hooks
  // componentDidMount
  useEffect(() => {
    fnGreeting();
    fetch(
      "https://api.retire-ph.com/get_user?auth_token=" +
        getCookie("auth_token"),
      { method: "GET" }
    ).then(async (res) => {
      const data = await res.json();
      console.log(data);
      data["user"]["sep_name"] = data["user"]["name"].split(" ");
      data["user"]["fname"] = data["user"]["sep_name"][0];
      const lname = data["user"]["sep_name"].slice(1).join(" ");
      data["user"]["lname"] = lname;
      setFullname(data["user"]["name"]);
      setProfileData(data["user"]);
    });
  }, []);

  // Methods
  // Set Greeting value in header
  let fnGreeting = () => {
    try {
      let arrMonths = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let arrDays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      let currentHour = new Date().getHours();

      // Get the greeting element
      let greeting = document.getElementById("js-greeting");
      let subgreeting = document.getElementById("js-subgreeting");

      if (greeting) {
        // Change the greeting based on the time of day
        if (currentHour >= 5 && currentHour < 12) {
          greeting.textContent = `Good morning, ${profileData?.fname}`;
        } else if (currentHour >= 12 && currentHour < 17) {
          greeting.textContent = `Good afternoon, ${profileData?.fname}`;
        } else {
          greeting.textContent = `Good evening, ${profileData?.fname}`;
        }
      }

      if (subgreeting) {
        subgreeting.textContent = `Today is ${
          arrDays[new Date().getDay()]
        }, ${new Date().getDate()} ${
          arrMonths[new Date().getMonth() - 1]
        } ${new Date().getFullYear()}`;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", fullname);
    formData.append("email", emailRef.current.value);
    formData.append("auth_token", getCookie("auth_token"));
    formData.append("profilePicture", imageRef.current.files[0] || "N/A");
    fetch("https://api.retire-ph.com/edit_profile", {
      method: "PUT",
      headers: {
        Authorization: getCookie("auth_token"),
      },
      body: formData,
    }).then(async (res) => {
      console.log(formData.get("name"));
      alert("Profile updated.");
      const data = await res.json();
      console.log(data);
    });
  };

  // Template
  return (
    <div>
      <div class="banner position-relative">
        <div class="section__container mx-auto w-100 h-100 d-flex align-items-md-center justify-content-between text-white px-3 px-md-4">
          <div class="pt-5 pt-md-0">
            <h2 class="fw-bold page-title fs-md-36 fs-24" id="js-greeting"></h2>
            <span class="fs-14 fs-md-18 page-desc" id="js-subgreeting"></span>
          </div>

          <div class="banner__image banner__image--masskara position-absolute d-md-none">
            <img
              class="w-100 h-100 object-fit-cover"
              src="img/asset-masskara.png"
            ></img>
          </div>

          <div class="banner__image banner__image--jeepney position-absolute end-0 d-none d-md-block">
            <img
              class="w-100 h-100 object-fit-cover"
              src="img/asset-jeepney.png"
            ></img>
          </div>
        </div>
        <div class="banner__image h-100 w-100 position-absolute top-0 z-n1">
          <img
            class="w-100 h-100 object-fit-cover"
            src="img/bg-retiree-home.png"
          ></img>
        </div>
      </div>

      <div class="section section__container position-relative z-1 bg-white mx-auto w-100 h-100 px-3 px-md-4">
        <h3 class="fw-bold fs-24 mb-5 text-center">My Profile</h3>
        <form class="form col-lg-6 col-md-9 mx-auto" onSubmit={handleSubmit}>
          <input
            type="hidden"
            id="name"
            value={fullname}
            defaultValue={profileData && profileData["name"]}
          />
          <div class="d-flex flex-md-row flex-column align-items-center my-5">
            <div class="profile position-relative rounded-circle mb-2 mb-lg-0 me-md-5 border-4 border">
              <img
                class="absolute rounded-circle object-fit-cover d-block section--profile-img js-profile-image"
                ref={profileRef}
                id="profile_view"
                src={
                  profileData &&
                  "data:image/*;base64," + profileData["profilePicture"]
                }
                alt="verify account icon"
              />

              <img
                class="rounded-circle object-fit-cover d-block section--profile-img js-profile-image"
                src="img/avatar.png"
                alt="verify account icon"
              />
            </div>
            <div class="d-flex flex-column justify-content-center mt-4 mt-sm-0">
              <button
                class="button button--section bg--blue rounded-2 text-white fw-bold mb-3 position-relative"
                type="button"
                data-bs-tooltip="tooltip"
                data-bs-placement="bottom"
                title="Change your profile photo"
                onClick={() => {
                  imageRef.current.click();
                }}
              >
                <span class="text-uppercase fs-14">CHANGE PHOTO</span>
              </button>
              <input
                class="top-0 start-0 w-0 h-0 position-absolute opacity-0 z-1"
                type="file"
                ref={imageRef}
                onChange={(e) => {
                  if (e.target.files.length > 0) {
                    const selectedImage = URL.createObjectURL(
                      e.target.files[0]
                    );
                    profileRef.current.src = selectedImage;
                  }
                }}
                id="profile_picture"
                role="button"
                accept="image/jpeg, image/png, image/jpg"
              />
              <button
                class="button button--section fs-14 border border-2 rounded-2 fw-bold d-flex align-items-center justify-content-center border--blue fc-blue"
                type="button"
                data-bs-tooltip="tooltip"
                data-bs-placement="bottom"
                title="Remove your profile photo"
                onClick={() => {
                  const view = document.getElementById("profile_view");
                  view.src = "img/avatar.png";
                }}
              >
                <span class="text-uppercase fs-14">REMOVE PHOTO</span>
              </button>
            </div>
          </div>

          <div class="mt-5">
            <div class="row">
              <div class="d-flex flex-column position-relative mb-3 col-md-6">
                <input
                  ref={fNameRef}
                  onChange={(e) => {
                    if (fNameRef && lNameRef) {
                      setFullname(
                        fNameRef.current.value + " " + lNameRef.current.value
                      );
                    }
                  }}
                  class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2"
                  id="first-name"
                  type="text"
                  placeholder="Enter your first name"
                  autoComplete=""
                  defaultValue={profileData?.fname}
                />
                <label
                  class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"
                  for="first-name"
                >
                  FIRST NAME
                </label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-6">
                <input
                  ref={lNameRef}
                  onChange={(e) => {
                    if (fNameRef && lNameRef) {
                      setFullname(
                        fNameRef.current.value + " " + lNameRef.current.value
                      );
                    }
                  }}
                  class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2"
                  id="last-name"
                  type="text"
                  placeholder="Enter your last name"
                  autoComplete=""
                  defaultValue={profileData?.lname}
                />
                <label
                  class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"
                  for="last-name"
                >
                  LAST NAME
                </label>
              </div>
            </div>
            <div class="row">
              <div class="d-flex flex-column position-relative mb-3 col-md-6">
                <input
                  ref={emailRef}
                  class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2"
                  id="email-adress"
                  type="text"
                  placeholder="Enter your email address"
                  defaultValue={profileData?.email}
                  autoComplete=""
                />
                <label
                  class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"
                  for="email-adress"
                >
                  Email Address
                </label>
              </div>
              <div class="d-flex flex-column position-relative mb-3 col-md-6">
                <input
                  class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2"
                  id="birthdate"
                  type="text"
                  placeholder="Enter your birth date"
                  defaultValue={profileData?.birthdate}
                  autoComplete=""
                />
                <label
                  class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"
                  for="birthdate"
                >
                  DATE OF BIRTH
                </label>
              </div>
            </div>
            <div class="row">
              <div class="d-flex flex-column position-relative mb-3 col-md-12">
                <input
                  class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2"
                  id="address"
                  type="text"
                  placeholder="Enter your address"
                  defaultValue={profileData?.city}
                  autoComplete=""
                />
                <label
                  class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute"
                  for="address"
                >
                  HOME ADDRESS
                </label>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-5">
              <button
                class="button button--section bg--blue rounded-2 text-white fw-bold d-flex align-items-center justify-content-center text-uppercase fs-14 ms-3"
                type="submit"
                data-bs-toggle="alert"
                data-bs-target=""
              >
                SAVE CHANGES
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RetireeProfile;
