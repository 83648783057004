// Dependencies
import { useEffect, useState } from 'react';
import { postRequest } from '../../../js/axios';
import { ADD_RULE } from '../../../constants/api-route';
import { STATUS_OK } from '../../../constants';

let AddRules = () => {
  // Variable
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  // Methods
  const addRule = () => {
    let body = {
      title: title,
      description: description
    }

    postRequest(ADD_RULE, body, (res) => {
      if (res.status === STATUS_OK) {
        window.location.reload();
      }
    });
  }

  // Hooks
  useEffect(() => {});

  // Template
  return (
    <div class="modal-content">
      <div class="modal-header border-0 flex-column align-items-start px-5 pt-5 mb-3">
        <h1 class="modal-title fs-18 fw-bold text-capitalize" id="modal-addRulesLabel">Add Rules</h1>
      </div>
      <div class="modal-body px-5">
        <div class="d-flex flex-column position-relative mb-5">
          <input class="form__input p-3 fs-14 border--gray border border-2 rounded-2" id="add-rules-title" type="text" placeholder="Enter rules title" onChange={(e) => setTitle(e.currentTarget.value)} />
          <label class="text-uppercase fw-semibold fs-14 bg-white px-1 top-0 position-absolute" for="add-rules-title">TITLE</label>
          </div>
        <div class="d-flex flex-column position-relative">
          <textarea class="form__input p-3 fs-14 border--gray border border-2 rounded-2 form--textarea" id="add-rules-description" type="text" placeholder="Enter rules description" onChange={(e) => setDescription(e.currentTarget.value)}> </textarea>
          <label class="text-uppercase fw-semibold fs-14 bg-white px-1 top-0 position-absolute" for="add-crypt-code">DESCRIPTION</label>
        </div>
      </div>
      <div class="modal-footer border-0 p-5 w-100">
        <div class="row mb-0 w-100 mx-0 justify-content-center">
          <div class="col-md-5 px-0 pe-md-1 ps-0 d-flex flex-column position-relative"><button class="button fs-14 border border-2 rounded-2 fw-bold d-flex align-items-center justify-content-center border--blue fc-blue" type="button" onClick={() => window.location.reload()} aria-label="Close">CANCEL</button></div>
          <div class="col-md-5 px-0 ps-md-1 d-flex flex-column position-relative"><button class="button bg--blue rounded-2 text-white fw-bold d-flex align-items-center justify-content-center text-uppercase fs-14" type="submit" onClick={() => addRule()}>SAVE CHANGES</button></div>
        </div>
      </div>
    </div>
  );
    
}

export default AddRules;