import { Outlet } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom';
import { verifySession } from '../../js/util';
import { useEffect } from "react";

function RetireeApplicationPageTemplate() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    verifySession(location.pathname, (bSession) => {
      bSession && navigate('/error');
    });
  }, []);
  
  return (
    <div>

        {/* <Header /> */}

        <Outlet />

        {/* <Footer /> */}

    </div>
  )
}

export default RetireeApplicationPageTemplate