import Cookies from 'js-cookie';

export const getCookie = (id) => {
    return Cookies.get(id);
}

export const setCookie = (id, value) => {
    Cookies.set(id, value);
}

export const removeCookie = (id) => {
    Cookies.remove(id, { path: '' })
}