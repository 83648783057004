// Dependencies
import { useEffect, useState } from 'react';
import { putRequest } from '../../../js/axios';
import { UPDATE_USER_TYPE } from '../../../constants/api-route';

let ChangeAccountType = ({id}) => {
  // Variable
  const [usertype, setUsertype] = useState('retiree');

  // Methods
  const saveChanges = () => {
    let body = {
      userID: id,
      userType: usertype
    };

    putRequest(UPDATE_USER_TYPE, body, (res) => {
      if (res.message === 'User type updated.') {
        window.location.reload();
      }
    });
  }

  // Hooks
  useEffect(() => {});

  // Template
  return (
    <div class="modal-content">
      <div class="modal-header border-0 flex-column align-items-start px-5 pt-5 mb-3">
        <h1 class="modal-title fs-18 fw-bold text-capitalize" id="modal-changeAccountTypeLabel">Change Account Type</h1>
      </div>
      <div class="modal-body px-5">
        <div class="d-flex flex-column position-relative">
          <div class="dropdown">
            <div class="dropdown-select position-relative">
              <select onChange={(e) => setUsertype(e.currentTarget.value)} class="form__input w-100 px-3 fs-md-14 border--gray border border-2 rounded-2 justify-content-between align-items-center d-flex" id="change-account-type" list="account_type" name="account_type" required="">
                <option value="retiree" disabled="" selected="">Select account type</option>
                <option value="marketer">Marketer</option>
                <option value="adminConsul">Admin - Consul</option>
                <option value="adminEvaluator">Admin - Evaluator</option>
              </select>
            </div>
            <label class="text-uppercase fw-semibold fs-14 bg-white px-1 top-0 position-absolute" for="change-account-type">STATUS</label>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0 p-5 w-100">
        <div class="row m-0 w-100">
          <div class="col-md-12 p-0 d-flex flex-column position-relative">
            <div class="row mb-0 w-100 mx-0 justify-content-center">
              <div class="col-md-5 px-0 pe-md-1 ps-0 d-flex flex-column position-relative">
                <button class="button fs-14 border border-2 rounded-2 fw-bold d-flex align-items-center justify-content-center border--blue fc-blue" type="button" onClick={() => window.location.reload()} aria-label="Close">CANCEL</button>
              </div>
              <div class="col-md-5 px-0 ps-md-1 d-flex flex-column position-relative">
                <button class="button bg--blue rounded-2 text-white fw-bold d-flex align-items-center justify-content-center text-uppercase fs-14" type="submit" onClick={saveChanges} >SAVE CHANGES</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
    
}

export default ChangeAccountType;