// Components
import Button from "../../components/button";
import LineChart from "../../components/line-chart";

// Dependencies
import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import { getRequest } from "../../../js/axios";
import { GET_COUNTS } from "../../../constants/api-route";
import { getCookie } from "../../../js/cookie";

/**
 * Superadmin Dashboard Page
 * @returns template
 *
 * @author Angelo C. Bustamante
 * @since 04/08/2024
 */
let SuperadminDashboard = () => {
  // Variable
  const [applicantsPerMonth, setApplicantsPerMonth] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [retirees, setRetirees] = useState(0);
  const [approved, setApproved] = useState(0);
  const [denied, setDenied] = useState(0);

  // Methods
  // Set Greeting value in header
  const fnGreeting = () => {
    try {
      let arrMonths = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let arrDays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      let currentHour = new Date().getHours();

      // Get the greeting element
      let greeting = document.getElementById("js-greeting");
      let subgreeting = document.getElementById("js-subgreeting");

      if (greeting) {
        // Change the greeting based on the time of day
        if (currentHour >= 5 && currentHour < 12) {
          greeting.textContent = "Good morning, admin!";
        } else if (currentHour >= 12 && currentHour < 17) {
          greeting.textContent = "Good afternoon, admin!";
        } else {
          greeting.textContent = "Good evening, admin!";
        }
      }

      if (subgreeting) {
        subgreeting.textContent = `Today is ${
          arrDays[new Date().getDay()]
        }, ${new Date().getDate()} ${
          arrMonths[new Date().getMonth() - 1]
        } ${new Date().getFullYear()}`;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setDashboardData = (data) => {
    let countList = data[0];
    setRetirees(countList.retiree_count);
    setApproved(countList.applicant_approved_count);
    setDenied(countList.app_denied_count);
    setApplicantsPerMonth([
      countList.jan_total_count,
      countList.feb_total_count,
      countList.march_total_count,
      countList.apr_total_count,
      countList.may_total_count,
      countList.jun_total_count,
      countList.july_total_count,
      countList.aug_total_count,
      countList.sept_total_count,
      countList.oct_total_count,
      countList.nov_total_count,
      countList.dec_total_count,
    ]);
  };

  // Hooks
  // Hook for onload
  useEffect(() => {
    fnGreeting();
    getRequest(GET_COUNTS, setDashboardData);
  }, []);

  const generateReport = async () => {
    try {
      let response = await fetch("https://api.retire-ph.com/generate_report", {
        method: "GET",
        headers: {
          "Content-Type": "application",
          Authorization: getCookie("auth_token"),
        },
      });

      // the response is an HTML page
      let html = await response.text();

      // create a PDF from the HTML using print dialog
      var wnd = window.open("about:blank", "", "_blank");
      wnd.document.write(html);
      wnd.print();
    } catch (error) {
      console.log(error);
    }
  };

  // Template
  return (
    <div class="overflow-y-auto w-100 h-100 px-3 py-5 p-lg-5">
      <div class="m-0 p-0 w-100">
        <div class="d-flex justify-content-between flex-column flex-sm-row mb-5">
          <div class="mb-3 mb-sm-0">
            <div class="d-flex">
              <h2 class="fs-md-24 fs-24 fw-bold" id="js-greeting"></h2>
              <img
                class="ms-2 h-100 emoji-waving"
                src="img/emoji-waving.png"
                alt=""
              />
            </div>
            <p class="fs-md-14 fs-12" id="js-subgreeting"></p>
          </div>
          <div>
            <Button
              addtlButtonClass="button--section d-flex align-items-center justify-content-center text-uppercase fs-14 ms-sm-auto"
              text="Generate Report"
              dropdown={true}
              img={true}
              src="img/icon-download.png"
            />
            <ul class="dropdown-menu p-0 dropdown--section">
              <li>
                <a class="dropdown-item p-3" onClick={generateReport}>
                  PDF
                </a>
              </li>
              <li>
                <hr class="dropdown-divider m-0" />
              </li>
              <li>
                <a class="dropdown-item p-3" href="/#">
                  CSV
                </a>
              </li>
              <li>
                <hr class="dropdown-divider m-0" />
              </li>
              <li>
                <a class="dropdown-item p-3" href="/#">
                  XLSX
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="section--dashboard p-0 m-0 mt-4">
        <div class="row mb-sm-5">
          <div class="col-md-4 mb-4 mb-md-0">
            <div class="card shadow-lg rounded-5 h-100 border-0">
              <div class="d-flex align-items-center">
                <p class="h6 fs-15 fw-bold fc-bluish-gray mb-0">
                  Total Retirees
                </p>
                <div class="icon d-flex justify-content-center align-items-center bg--blue rounded ms-auto">
                  <img
                    class="img-fluid d-block shadow"
                    src="img/icon-dashboard-retirees.png"
                    alt=""
                  />
                </div>
              </div>
              <p class="h1 fw-bold fs-48 fc-bluish-gray mb-0">{retirees}</p>
            </div>
          </div>
          <div class="col-md-4 mb-4 mb-md-0">
            <div class="card shadow-lg rounded-5 h-100 border-0">
              <div class="d-flex align-items-center">
                <p class="h6 fs-15 fw-bold fc-bluish-gray mb-0">
                  Total Applicants Approved
                </p>
                <div class="icon d-flex justify-content-center align-items-center bg--blue rounded ms-auto">
                  <img
                    class="img-fluid d-block shadow"
                    src="img/icon-like.png"
                    alt=""
                  />
                </div>
              </div>
              <p class="h1 fw-bold fs-48 fc-bluish-gray mb-0">{approved}</p>
            </div>
          </div>
          <div class="col-md-4 mb-4 mb-md-0">
            <div class="card shadow-lg rounded-5 h-100 border-0">
              <div class="d-flex align-items-center">
                <p class="h6 fs-15 fw-bold fc-bluish-gray mb-0">
                  Total Applicants Denied
                </p>
                <div class="icon d-flex justify-content-center align-items-center bg--blue rounded ms-auto">
                  <img
                    class="img-fluid d-block shadow"
                    src="img/icon-block.png"
                    alt=""
                  />
                </div>
              </div>
              <p class="h1 fw-bold fs-48 fc-bluish-gray mb-0">{denied}</p>
            </div>
          </div>
        </div>
        <div class="card shadow-lg rounded-5 border-0">
          <p class="pb-3 h6 fs-15 fw-bold fc-bluish-gray">
            Total Applicants Per Month
          </p>
          <LineChart value={applicantsPerMonth} />
        </div>
      </div>
    </div>
  );
};

export default SuperadminDashboard;
