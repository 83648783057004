// Dependencies
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GET_SPECIFIC_APPLICATION } from '../../../constants/api-route';
import { getRequest } from '../../../js/axios';

let AdminViewApplicant = () => {
  // Variable
  const [applicant, setApplicant] = useState({})
  let params = useParams();

  // Methods

  // Hooks
  useEffect(() => {
    getRequest(GET_SPECIFIC_APPLICATION, (data) => {
        setApplicant(data[0]);
        console.log(data[0])
    }, { userID: params.userID });
  }, []);

  // Template
  return (
    <div class="overflow-y-auto w-100 h-100 px-3 py-5 p-lg-5">
        <div class="breadcrumb fw-bold fs-12 mb-5">NEW APPLICANTS 
            <span class="icon-arrow-right"></span>
            <span class="applicant-name">Applicant Name</span>
        </div>
        <nav class="nav nav-pills">
            <a class="fs-md-14 text-center nav-link active" id="nav-principal-retiree" data-bs-toggle="tab" data-bs-target="#principal-retiree" aria-current="page" type="button" role="tab" aria-controls="principal-retiree" aria-selected="true">
                <div class="tab-pill-image me-2">
                    <span class="tab-pill-icon w-100 h-100 d-block"></span>
                </div>
                <span>PRINCIPAL RETIREE</span>
            </a>
            <a class="fs-md-14 text-center nav-link" id="nav-family-member" data-bs-toggle="tab" data-bs-target="#family-member" type="button" role="tab" aria-controls="family-member"
                aria-selected="false">
                <div class="tab-pill-image me-2">
                    <span class="tab-pill-icon w-100 h-100 d-block"></span>
                </div>
                <span>FAMILY MEMBER</span>
            </a>
            <a class="fs-md-14 text-center nav-link" id="nav-parents" data-bs-toggle="tab" data-bs-target="#parents" type="button" role="tab"
                aria-controls="parents" aria-selected="false">
                <div class="tab-pill-image me-2">
                    <span class="tab-pill-icon w-100 h-100 d-block"></span>
                </div>
                <span>PARENTS</span>
            </a>
            <a class="fs-md-14 text-center nav-link" id="nav-education-and-career" data-bs-toggle="tab" data-bs-target="#education-and-career"
                type="button" role="tab" aria-controls="education-and-career" aria-selected="false">
                <div class="tab-pill-image me-2">
                    <span class="tab-pill-icon w-100 h-100 d-block"></span>
                </div>
                <span>EDUCATION AND CAREER</span>
            </a>
            <a class="fs-md-14 text-center nav-link" id="nav-attachments" data-bs-toggle="tab" data-bs-target="#attachments" type="button"
                role="tab" aria-controls="attachments" aria-selected="false">
                <div class="tab-pill-image me-2">
                    <span class="tab-pill-icon w-100 h-100 d-block"></span>
                </div>
                <span>ATTACHMENTS</span>
            </a>
        </nav>
        <div class="tab-content">
            <div class="tab-pane fade show active" id="principal-retiree" role="tabpanel" aria-labelledby="nav-principal-retiree" tabindex="0">
                <div class="panel bg-white">
                    <h5 class="fw-bold">Basic Information</h5>
                    <div class="row mb-2 mt-4 mb-sm-4">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">FULL NAME</p>
                            <p class="fs-16 fw-bold text-capitalize">{`${applicant.firstName} ${applicant.lastName}`}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">ALIAS (AKA)</p>
                            <p class="fw-bold fs-16">{applicant.alias}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">RELIGION</p>
                            <p class="fw-bold fs-16">{applicant.religion}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">GENDER</p>
                            <p class="fw-bold fs-16">{applicant.gender}</p>
                        </div>
                    </div>
                    <div class="row mb-2 mb-sm-4">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">DATE OF BIRTH</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.dateOfBirth}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">PLACE OF BIRTH</p>
                            <p class="fw-bold fs-16">{applicant.placeOfBirth}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">NATIONALITY</p>
                            <p class="fw-bold fs-16">{applicant.nationality}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">ID NO.</p>
                            <p class="fw-bold fs-16">{applicant.idNo}</p>
                        </div>
                    </div>
                    <div class="row mb-2 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">CIVIL STATUS</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.civilStatus}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">HEIGHT</p>
                            <p class="fw-bold fs-16">{applicant.heightCM}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">WEIGHT</p>
                            <p class="fw-bold fs-16">{applicant.weightKG}</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white bordered-panel rounded-bottom-4">
                    <h5 class="fw-bold">Passport Information</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">PASSPORT NO.</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.passportNo}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">PLACE OF ISSUE</p>
                            <p class="fw-bold fs-16">{applicant.placeOfIssue}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">DATE OF ISSUE</p>
                            <p class="fw-bold fs-16">{new Date(applicant.dateOfIssue).toLocaleDateString("en-US")}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">VALID UNTIL</p>
                            <p class="fw-bold fs-16">{new Date(applicant.validUntil).toLocaleDateString("en-US")}</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white">
                    <h5 class="fw-bold">Address Information</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-12 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">HOME COUNTRY ADDRESS</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.homeCountryAddress}</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">TELEPHONE NO.</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.telephoneNo}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">FAX NO.</p>
                            <p class="fw-bold fs-16">{applicant.faxNo}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">MOBILE NO.</p>
                            <p class="fw-bold fs-16">{applicant.mobileNo}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">E-MAIL</p>
                            <p class="fw-bold fs-16">{applicant.email}</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-12 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">PRIMARY ADDRESS IN THE PHILIPPINES</p>
                            <p class="fs-16 fw-bold text-capitalize">1749 Escuela Street, Guadalupe Nuevo, Pasig City, Metro Manila</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-12 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">SECONDARY ADDRESS IN THE PHILIPPINES</p>
                            <p class="fs-16 fw-bold text-capitalize">1749 Escuela Street, Guadalupe Nuevo, Pasig City, Metro Manila</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">TELEPHONE NO.</p>
                            <p class="fs-16 fw-bold text-capitalize">PSA2N23123</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">FAX NO.</p>
                            <p class="fw-bold fs-16">PSA2N23123</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">MOBILE NO.</p>
                            <p class="fw-bold fs-16">09554639043</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">E-MAIL</p>
                            <p class="fw-bold fs-16">adrian@gmail.com</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white bordered-panel rounded-bottom-4">
                    <h5 class="fw-bold">Entry to the Philippines</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-4 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">DATE OF ARRIVAL IN THE PHILIPPINES</p>
                            <p class="fs-16 fw-bold text-capitalize">{new Date(applicant.dateOfArrivalPH).toLocaleDateString("en-US")}</p>
                        </div>
                        <div class="col-md-4">
                            <p class="fs-14 text-uppercase">EXPIRATION DATE OF TOURIST VISA</p>
                            <p class="fw-bold fs-16">{new Date(applicant.touristExpirationDate).toLocaleDateString("en-US")}</p>
                        </div>
                        <div class="col-md-4">
                            <p class="fs-14 text-uppercase">ENTRY VISA TO THE PHILIPPINES</p>
                            <p class="fw-bold fs-16">{applicant.entryVisaPH}</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-4 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">VISITED PHILIPPINES PRIOR TO THIS TRAVEL?</p>
                            <p class="fs-16 fw-bold text-capitalize">{`${applicant.isPHVisited ? 'Yes' : 'No' }${applicant.isPHVisited && ' • '}${applicant.visaTypeTwo}`}</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white">
                    <h5 class="fw-bold">Last Three Years Residence in the Philippines</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">PERIOD OF STAY</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.periodOfStayOne}</p>
                        </div>
                        <div class="col-md-6">
                            <p class="fs-14 text-uppercase">ADDRESS</p>
                            <p class="fw-bold fs-16">{applicant.periodOfStayOneAddress}</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">PERIOD OF STAY</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.periodOfStayTwo}</p>
                        </div>
                        <div class="col-md-6">
                            <p class="fs-14 text-uppercase">ADDRESS</p>
                            <p class="fw-bold fs-16">{applicant.periodOfStayTwoAddress}</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">PERIOD OF STAY</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.periodOfStayThree}</p>
                        </div>
                        <div class="col-md-6">
                            <p class="fs-14 text-uppercase">ADDRESS</p>
                            <p class="fw-bold fs-16">{applicant.periodOfStayThreeAddress}</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">PERIOD OF STAY</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.periodOfStayFour}</p>
                        </div>
                        <div class="col-md-6">
                            <p class="fs-14 text-uppercase">ADDRESS</p>
                            <p class="fw-bold fs-16">{applicant.periodOfStayFourAddress}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="family-member" role="tabpanel" aria-labelledby="nav-family-member" tabindex="0">
                <div class="panel bg-white bordered-panel rounded-bottom-4">
                    <h5 class="fw-bold">Spouse</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">FULL NAME</p>
                            <p class="fs-16 fw-bold text-capitalize">Helen Gomez</p>
                        </div>
                        <div class="col-md-2">
                            <p class="fs-14 text-uppercase">DATE OF BIRTH</p>
                            <p class="fw-bold fs-16">12/24/1999</p>
                        </div>
                        <div class="col-md-2">
                            <p class="fs-14 text-uppercase">AGE</p>
                            <p class="fw-bold fs-16">25 years old</p>
                        </div>
                        <div class="col-md-2">
                            <p class="fs-14 text-uppercase">ID NO.</p>
                            <p class="fw-bold fs-16">PSA2N23123</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">INCLUDED IN APPLICATION?</p>
                            <p class="fw-bold fs-16">Yes</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white">
                    <h5 class="fw-bold">Child</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">FULL NAME</p>
                            <p class="fs-16 fw-bold text-capitalize">Helen Gomez</p>
                        </div>
                        <div class="col-md-2">
                            <p class="fs-14 text-uppercase">DATE OF BIRTH</p>
                            <p class="fw-bold fs-16">12/24/1999</p>
                        </div>
                        <div class="col-md-2">
                            <p class="fs-14 text-uppercase">AGE</p>
                            <p class="fw-bold fs-16">25 years old</p>
                        </div>
                        <div class="col-md-2">
                            <p class="fs-14 text-uppercase">ID NO.</p>
                            <p class="fw-bold fs-16">PSA2N23123</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">INCLUDED IN APPLICATION?</p>
                            <p class="fw-bold fs-16">Yes</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="parents" role="tabpanel" aria-labelledby="nav-parents" tabindex="0">
                <div class="panel bg-white">
                    <h5 class="fw-bold">Father</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">FULL NAME</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.nameOfFather}</p>
                        </div>
                        <div class="col-md-2">
                            <p class="fs-14 text-uppercase">AGE</p>
                            <p class="fw-bold fs-16">{applicant.ageOfFather} years old</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white bordered-panel rounded-bottom-4">
                    <h5 class="fw-bold">Mother</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">FULL NAME</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.nameOfMother}</p>
                        </div>
                        <div class="col-md-2">
                            <p class="fs-14 text-uppercase">AGE</p>
                            <p class="fw-bold fs-16">{applicant.ageOfMother} years old</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white">
                    <h5 class="fw-bold">Emergency Contact Person</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">FULL NAME</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.nameContactPerson}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">CONTACT NO.</p>
                            <p class="fw-bold fs-16">{applicant.contactPersonNum}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">NATIONALITY</p>
                            <p class="fw-bold fs-16">{applicant.contactPersonNationality}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">RELATIONSHIP</p>
                            <p class="fw-bold fs-16">{applicant.contactPersonRelationship}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="education-and-career" role="tabpanel" aria-labelledby="nav-education-and-career" tabindex="0">
                <div class="panel bg-white bordered-panel rounded-bottom-4">
                    <h5 class="fw-bold">Education</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">COLLEGE/UNIVERSITY</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.educationalAttainmentOne}</p>
                        </div>
                        <div class="col-md-6">
                            <p class="fs-14 text-uppercase">SCHOOL AND LOCATION</p>
                            <p class="fw-bold fs-16">{applicant.schoolLocationOne}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">FROM/TO</p>
                            <p class="fw-bold fs-16">{applicant.schoolOneFrom}</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">HIGHSCHOOL</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.educationalAttainmentTwo}</p>
                        </div>
                        <div class="col-md-6">
                            <p class="fs-14 text-uppercase">SCHOOL AND LOCATION</p>
                            <p class="fw-bold fs-16">{applicant.schoolLocationTwo}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">FROM/TO</p>
                            <p class="fw-bold fs-16">{applicant.schoolTwoFrom}</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">ELEMENTARY</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.educationalAttainmentThree}</p>
                        </div>
                        <div class="col-md-6">
                            <p class="fs-14 text-uppercase">SCHOOL AND LOCATION</p>
                            <p class="fw-bold fs-16">{applicant.schoolLocationThree}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">FROM/TO</p>
                            <p class="fw-bold fs-16">{applicant.schoolThreeFrom}</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white">
                    <h5 class="fw-bold">Present Company / Business</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-3 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">NAME</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.nameOfPresentCompany}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">CONTACT NO.</p>
                            <p class="fw-bold fs-16">{applicant.presentCompanyContactNum}</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white bordered-panel rounded-bottom-4">
                    <h5 class="fw-bold">Other Plans/Future Actions in the Philippines</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-4 mb-2 mb-sm-0">
                            <p class="fs-16 fw-bold text-capitalize">{applicant.futureActions}</p>
                        </div>
                    </div>
                </div>
                <div class="panel bg-white">
                    <h5 class="fw-bold">Employment in the Last Three (3) Years</h5>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-6 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">COMPANY NAME AND ADDRESS</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.employmentCompanyNameAddressOne}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">JOB TITLE</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.employmentCompanyJobTitleOne}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">FROM/TO</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.employmentCompanyOneFromTo}</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-6 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">COMPANY NAME AND ADDRESS</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.employmentCompanyNameAddressTwo}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">JOB TITLE</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.employmentCompanyJobTitleTwo}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">FROM/TO</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.employmentCompanyTwoFromTo}</p>
                        </div>
                    </div>
                    <div class="row mb-2 mt-4 mb-sm-2">
                        <div class="col-md-6 mb-2 mb-sm-0">
                            <p class="fs-14 text-uppercase">COMPANY NAME AND ADDRESS</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.employmentCompanyNameAddressThree}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">JOB TITLE</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.employmentCompanyJobTitleThree}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="fs-14 text-uppercase">FROM/TO</p>
                            <p class="fs-16 fw-bold text-capitalize">{applicant.employmentCompanyThreeFromTo}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="attachments" role="tabpanel" aria-labelledby="nav-attachments" tabindex="0">
                <div class="panel bg-white bordered-panel rounded-bottom-4">
                    <h5 class="fw-bold">Attachments
                        <div class="col-md-12 mt-4 mb-4">
                            <p class="fs-14 text-uppercase">POLICE CLEARANCE</p>
                            <p class="fs-16"><a href="download">policeclearance.jpg</a></p>
                        </div>
                        <div class="col-md-12 mb-4">
                            <p class="fs-14 text-uppercase">NBI CLEARANCE</p>
                            <p class="fs-16"><a href="download">policeclearance.jpg</a></p>
                        </div>
                        <div class="col-md-12 mb-4">
                            <p class="fs-14 text-uppercase">AFFIDAVIT OF UNDERTAKING</p>
                            <p class="fs-16"><a href="download">policeclearance.jpg</a></p>
                        </div>
                        <div class="col-md-12 mb-4">
                            <p class="fs-14 text-uppercase">MEDICAL CERTIFICATE</p>
                            <p class="fs-16"><a href="download">policeclearance.jpg</a></p>
                        </div>
                        <div class="col-md-12 mb-4">
                            <p class="fs-14 text-uppercase">BANK CERTIFICATION</p>
                            <p class="fs-16"><a href="download">policeclearance.jpg</a></p>
                        </div>
                        <div class="col-md-3 mb-4">
                            <p class="fs-14 text-uppercase">PASSPORT</p>
                            <p class="fs-16"><a href="download">policeclearance.jpg</a></p>
                        </div>
                    </h5>
                </div>
            </div>
        </div>
    </div>
  );
    
}

export default AdminViewApplicant;