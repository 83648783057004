// Dependencies
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { setInputValue, showPassword } from '../../js/util';
import { SIGN_UP } from '../../constants/api-route';
import { postRequest } from '../../js/axios';

// Components
import Button from '../components/button';
import Modal from '../components/modal';
import QuickNav from "../components/quicknav";
import { STATUS_OK } from '../../constants';
import { showErrorToast } from '../../js/toast';

/**
 * Signup Page
 * @returns template
 * 
 * @author Angelo C. Bustamante
 * @since 04/06/2024 
 */
let Signup = () => {
  // State
  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [password, setPassword] = useState('');
  const [otp_id, setOTPid] = useState('');

  // Variable
  let details = {
    email: email,
    otp_id: otp_id
  }

  // Methods
  const singupUser = () => {
    let body = {
      email       : email,
      mobileNumber: phonenumber,
      name        : 'No Name',
      barangay    : 'No Address',
      city        : 'No Address',
      state       : 'No Address',
      country     : 'No Address',
      password    : password,
    };

    if (email === '' || phonenumber === '' || password === '') {
      (email === '') && showErrorToast('Email Address is empty');
      (phonenumber === '') && showErrorToast('Phone Number is empty');
      (password === '') && showErrorToast('Password is empty');
      return;
    }

    postRequest(SIGN_UP, body, (res) => {
      if (res.status === STATUS_OK) {
        setVisible(true);
        setOTPid(res.otp_id);
      } else {
        showErrorToast('Something Went Wrong');
      }
      console.log(res);
    });
  };

  // Hooks
  // componentDidMount
  useEffect(() => {
    showPassword();
  }, []);

  // Template
  return (
    <div className="section--login position-relative bg--blue min-vh-100 d-flex align-items-center justify-content-center container-fluid p-0">
        <div className="start-0 top-0 col min-vh-100">
          <iframe className="d-none d-lg-flex min-vh-100 w-100" src="https://www.youtube.com/embed/K2Cjf8gHH3Y?si=wca7Q8LELZ5E-pvV&amp;amp;controls=0&amp;autoplay=1&amp;mute=1&amp;loop=1&amp;playlist=K2Cjf8gHH3Y" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen="allowFullScreen"></iframe>
        </div>
        <div className="p-0 p-sm-5 d-flex flex-column col-lg col-12 container-fluid vh-100">
          <div className='bg--login px-3 h-100 d-flex align-items-center'>
            <div className="card p-5 shadow-lg rounded-4 m-auto">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div className='d-flex flex-column'>
                    <h2 className="fw-bold fs-md-24 fs-20"> Welcome</h2>
                    <span className="fs-md-14 fs-12">Let's get started!</span>
                  </div>
                
                  <div className='company-logo'>
                    <img className="w-100 h-100 object-fit-contain" src="./icon/logo.png" alt=""/>
                  </div>
                </div>
                
                <form class="form mt-4">
                  <div class="d-flex flex-column position-relative mb-3">
                    <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="email-adress" type="text" placeholder="Enter your email address" autoComplete="" onChange={(event) => setInputValue(setEmail, event)}/>
                    <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute" for="email-adress">Email Address</label>
                  </div>
                  <div class="d-flex flex-column position-relative mb-3">
                    <input class="form__input p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="js-phone-number" type="number" placeholder="Enter your phone number" onChange={(event) => setInputValue(setPhonenumber, event)}/>
                    <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute" for="js-phone-number">Phone number</label>
                  </div>
                  <div class="d-flex flex-column position-relative">
                    <input class="form__input form__input--password p-3 fs-md-14 fs-12 border--gray border border-2 rounded-2" id="password" type="password" placeholder="Enter your password" onChange={(event) => setInputValue(setPassword, event)}/>
                    <label class="text-uppercase fw-semibold fs-md-14 fs-12 bg-white px-1 top-0 position-absolute" for="password">Password</label>
                    <span class="fas fa-lock position-absolute" id="js-lock-password"></span>
                  </div>
                  {/* <Button text="Sign Up" addtlButtonClass="w-100 mt-5 mb-3" addtlLabelClass="fs-12" modal={true} modalTarget="#modal-verifyAccount"/> */}
                  <Button text="Sign Up" addtlButtonClass="w-100 mt-5 mb-3" addtlLabelClass="fs-12" onClickAction={ () => singupUser() }/>
                  <span class="fs-md-14 fs-12 d-block text-center">Already have an account? Click <Link class="link fw-bold fc-green" to="/">here</Link><span> to log in.</span></span>
                  <Modal displayModal={visible} onHide={() => setVisible(false)} signup={true} verify={true} details={ details }/>
                </form>
            </div>
          </div>
        </div>
        {/* <QuickNav/> */}
    </div>
  );
}

export default Signup;
  