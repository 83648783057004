// Dependencies
import { useEffect, useState } from 'react';
import Button from '../../components/button';

//Components
import Modal from '../../components/modal';

let RetireeOtherServices = () => {
  // Variable
  const [visible, setVisible] = useState(false);

  // Methods

  // Hooks
  useEffect(() => {}, []);

  // Template
  return (
    <div>
      <div class="banner position-relative">
        <div class="section__container flex-column mx-auto w-100 h-100 d-flex justify-content-center text-white px-3 px-md-4">
          <h2 class="fw-bold page-title fs-md-36 fs-24">Other Services</h2>
          <span class="fs-14 fs-md-18">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas metus ante, pharetra</span>
        </div>
        <div class="banner__image h-100 w-100 position-absolute top-0 z-n1">
          <img class="w-100 h-100 object-fit-cover" src="img/bg-other-services.png"></img>
        </div>
      </div>
      <div class="section section__container position-relative z-1 bg-white mx-auto w-100 h-100 px-3 px-md-4">
        <ul class="d-flex flex-column flex-md-row justify-content-center align-items-center text-white">
          <li class="rounded-6 me-md-4">
            <button class='button button--retiree bg--blue d-flex flex-column align-items-center justify-content-center rounded-6 px-4 py-5' onClick={() => setVisible(true)}>
              <div class="button__image">
                <img class="object-fit-cover h-100 w-100" src="img/icon-oservices-renewal.png" alt="Apply New Icon" />
              </div>
              <span class="fs-14 fw-bold text-center mt-3">SBMA/CDC PROPERTIES RENEWAL</span>
            </button>
          </li>
        </ul>
      </div>
      <Modal displayModal={visible} onHide={() => setVisible(false)} otherservices={true}/>
    </div>
    // <div class="retiree-section-container">
    //   <div class="banner retiree-other-services relative">
    //     <div class="page-text container-fluid px-4">
    //       <div class="fw-bold page-title fs-24">Other Services</div>
    //       <div class="fs-14 fs-md-18 page-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas metus ante, pharetra</div>
    //     </div>
    //   </div>
    //   <div class="container-fluid retiree-section-content section">
    //     <div class="blue-card-container">
    //       <button class="blue-card-panel" data-bs-toggle="modal" data-bs-target="#modal-otherServices">
    //         <img src="img/icon-oservices-renewal.png" />
    //         <div class="fs-14 fw-bold">SBMA/CDC PROPERTIES RENEWAL</div>
    //       </button>
    //       <button class="blue-card-panel">
    //         <img src="img/icon-oservices-renewal.png" />
    //         <div class="fs-14 fw-bold">SBMA/CDC PROPERTIES RENEWAL</div>
    //       </button>
    //       <button class="blue-card-panel">
    //         <img src="img/icon-oservices-renewal.png" />
    //         <div class="fs-14 fw-bold">SBMA/CDC PROPERTIES RENEWAL</div>
    //       </button>
    //     </div>
    //   </div>
    //   <Modal otherservices={true}/>
    // </div>
  );
    
}

export default RetireeOtherServices;