// Dependencies
import { useDispatch } from 'react-redux';
import { changePage } from '../../state/slice';
import { useEffect } from 'react';
import { menu, resetCookie } from '../../js/util';

import { Link } from "react-router-dom";

/**
 * Header Component
 * @returns template
 * 
 * @author Juliesse Ann Rasco
 * @since 04/11/2024 
 */
let Header = () => {
  // Variable
  // const dispatch = useDispatch();

  // Methods

  // Hooks
  useEffect(() => {
    menu();
  }, []);

  // Template
  return (
    <header class="header py-2">
      <div class="section__container h-100 d-flex align-items-center justify-content-between mx-auto w-100 px-3 px-md-4 px-lg-0">
        <Link to="home" class="header__image rounded-circle d-block"><img class="w-100 h-100 object-fit-cover" src="icon/logo.png" alt=""/></Link>
        <nav class="d-none d-md-flex align-items-center h-100 fs-14 fw-bold">
          <Link to="home" class="d-flex align-items-center link p-3 rounded-2">Home</Link>
          <Link to="application/main" class="d-flex align-items-center link p-3 rounded-2">Application</Link>
          <Link to="otherservices" class="d-flex align-items-center link p-3 rounded-2">Other Services</Link>
          <div class="dropdown">
            <div class="d-flex align-items-center ms-5" data-bs-toggle="dropdown" role="button">
              <div class="header__image--profile rounded-circle border-2">
                <img class="object-fit-cover w-100 h-100" src="img/icon-profile-pic.png" alt=""/>
              </div>
              <span class="fa fa-chevron-down ms-2 fs-12"></span>
            </div>
            <ul class="dropdown-menu dropdown-menu-end p-0 rounded-2">
              <li><Link to="profile" class="rounded-top-2 link dropdown-item p-3 fs-14">My Profile</Link></li>
              <li>
                <hr class="dropdown-divider m-0" />
              </li>
              <li><Link to="/" class="rounded-bottom-2 link dropdown-item p-3 fs-14 rounded-bottom-2">Logout</Link></li>
            </ul>
          </div>
          
        </nav>
        <input class="trigger position-absolute overflow-hidden p-0 border-0 end-0 visually-hidden" id="js-menu" type="checkbox" />
        <label class="d-block d-sm-none" for="js-menu">
          <div class="header__burger position-relative flex-column d-flex d-sm-none">
            <span class="header__burger-line position-absolute bg--blue rounded-2 w-100"> </span>
            <span class="header__burger-line position-absolute bg--blue rounded-2 w-100"> </span>
            <span class="header__burger-line position-absolute bg--blue rounded-2 w-100"> </span>
            <span class="header__burger-line position-absolute bg--blue rounded-2 w-100"> </span>
            <span class="header__burger-line position-absolute bg--blue rounded-2 w-100"> </span>
          </div>
        </label>
        <div class="overlay top-0 start-0 w-100 position-fixed visibility-hidden d-none d-sm-none vh-100" id="js-overlay"></div>
        <div class="panel panel--mobile w-75 bg--blue top-0 end-0 position-fixed d-lg-none vh-100" id="js-panel">
		      <nav class="panel__navigation d-flex flex-column align-items-center h-100 justify-content-center text-start fc-dark" aria-labelledby="panel-mobile-label">
            <h2 class="visually-hidden" id="panel-mobile-label">Navigation</h2>
            <Link to="home" class="js-sidebar-link w-100 py-3 px-4 rounded-2 fw-semibold fs-14 d-flex align-items-center mb-4 text-white">Home</Link>
            <Link to="application/main" class="js-sidebar-link w-100 py-3 px-4 rounded-2 fw-semibold fs-14 d-flex align-items-center mb-4 text-white">Application</Link>
            <Link to="otherservices" class="js-sidebar-link w-100 py-3 px-4 rounded-2 fw-semibold fs-14 d-flex align-items-center mb-4 text-white">Other Services</Link>
            <Link to="profile" class="js-sidebar-link w-100 py-3 px-4 rounded-2 fw-semibold fs-14 d-flex align-items-center mb-4 text-white">My Profile</Link>
            <Link to="/" class="js-sidebar-link w-100 py-3 px-4 rounded-2 fw-semibold fs-14 d-flex align-items-center mb-4 text-white" onClick={resetCookie}>Logout</Link>
          </nav>
        </div>
      </div>
    </header>
    // <div class="retiree container-fluid panel bg--white d-flex align-items-center justify-content-between py-2 w-100">
    //   <Link to="home" class="company-logo rounded-circle d-block"><img class="w-100 h-100 object-fit-cover" src="icon/logo.png" alt=""/></Link>
    //   <div class="col-md-9 my-auto">
    //       <div class="menu d-none d-sm-flex fs-14 fw-bold">
    //         <Link to="home" class="d-flex align-items-center link px-4 px-md-3 px-lg-4 rounded-2 transition hover:bg-gray-200">Home</Link>
    //         <Link to="application/main" class="d-flex align-items-center link px-4 px-md-3 px-lg-4 rounded-2 transition hover:bg-gray-200">Application</Link>
    //         <Link to="otherservices" class="d-flex align-items-center link px-4 px-md-3 px-lg-4 rounded-2 transition hover:bg-gray-200">Other Services</Link>
    //         <div class="d-flex align-items-center profile ms-5" data-bs-toggle="dropdown" role="button">
    //           <img src="img/icon-profile-pic.png" />
    //           <span role="button" class="icon-arrow-down"></span>
    //         </div>
    //         <ul class="dropdown-menu dropdown-menu-end dropdown--section">
    //             <li><Link to="profile" class="link dropdown-item">My Profile</Link></li>
    //             <li>
    //               <hr class="dropdown-divider m-0" />
    //             </li>
    //             <li><Link to="/" class="link dropdown-item rounded-bottom-2">Logout</Link></li>
    //         </ul>
    //       </div>
    //     </div>
    //     <input class="trigger position-absolute overflow-hidden p-0 border-0 end-0 visually-hidden" id="js-menu" type="checkbox" /><label class="d-block d-sm-none" for="js-menu"
		// 		><div class="header__burger position-relative flex-column d-flex d-sm-none">
		// 			<span class="header__burger-line position-absolute bg--blue rounded-2 w-100"> </span><span class="header__burger-line position-absolute bg--blue rounded-2 w-100"> </span
		// 			><span class="header__burger-line position-absolute bg--blue rounded-2 w-100"> </span><span class="header__burger-line position-absolute bg--blue rounded-2 w-100"> </span
		// 			><span class="header__burger-line position-absolute bg--blue rounded-2 w-100"> </span></div
		// 	></label>
		// 	<div class="overlay top-0 start-0 w-100 position-fixed visibility-hidden d-none d-sm-none vh-100" id="js-overlay"></div>
    //   <div class="panel panel--mobile w-75 bg--blue top-0 end-0 position-fixed d-lg-none vh-100" id="js-panel">
		// 		<nav class="panel__navigation d-flex flex-column align-items-center h-100 justify-content-center text-start fc-dark" aria-labelledby="panel-mobile-label">
		// 			<h2 class="visually-hidden" id="panel-mobile-label">Navigation</h2>
    //       <Link to="home" class="js-sidebar-link w-100 py-3 px-4 rounded-2 fw-semibold fs-14 d-flex align-items-center mb-4 text-white">Home</Link>
    //       <Link to="application/main" class="js-sidebar-link w-100 py-3 px-4 rounded-2 fw-semibold fs-14 d-flex align-items-center mb-4 text-white">Application</Link>
    //       <Link to="otherservices" class="js-sidebar-link w-100 py-3 px-4 rounded-2 fw-semibold fs-14 d-flex align-items-center mb-4 text-white">Other Services</Link>
    //       <Link to="profile" class="js-sidebar-link w-100 py-3 px-4 rounded-2 fw-semibold fs-14 d-flex align-items-center mb-4 text-white">My Profile</Link>
		// 			<Link to="/" class="js-sidebar-link w-100 py-3 px-4 rounded-2 fw-semibold fs-14 d-flex align-items-center mb-4 text-white">Logout</Link>
					
		// 		</nav>
		// 	</div>
    // </div>
  );
    
}

export default Header;