// Dependencies
import { useEffect, useState } from 'react';
import { getRowId, icon } from '../../../js/util';
import { getRequest } from '../../../js/axios';

// Components
import Modal from '../../components/modal';
import Table from '../../components/table';
import IconButton from '../../components/iconbutton';
import { GET_APPLICATION } from '../../../constants/api-route';
import { Link, useNavigate } from 'react-router-dom';
import { getCookie } from '../../../js/cookie';
import { ADMIN_TYPE, AUTH_TOKEN } from '../../../constants/cookie';
import { CONSUL } from '../../../constants';

let AdminConsulNewApplicants = ({ title, description }) => {
  // Variable
  const [id, setId] = useState(null);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [applicants, setapplicants] = useState([]);
  const navigate = useNavigate();
  const columns = [
    {field: 'chosenTimeSlot', header: 'TIMESTAMP'},
    {field: 'idNo', header: 'APPLICATION NO.'},
    {field: 'name', header: 'FULL NAME'}
  ];

  // Methods
  const actionColumnBody = (data) => {
    return (
    <div>
      <span onClick={() => {} } >
        <Link class="far fa-eye js-icon-button" to={`/admin/viewapplicant/${data.userID}`}/>
      </span>
      <IconButton icon={true} addtlButtonClass={`ms-4 icon row-id-${data.applicationID}`} addtlIconClass="far fa-pen-to-square js-icon-button" onClickAction={(event) => updateApplicationStatus(event)}/>
    </div>);
  }

  const getapplicants = (data) => {
    let applicantData = [];
    data.forEach((obj)=> {
      let objapplicant = {
        userID: obj.userID,
        applicationID: obj.applicationID,
        chosenTimeSlot: new Date(obj.chosenTimeSlot).toLocaleDateString("en-US"),
        idNo: obj.idNo,
        name: `${obj.firstName} ${obj.lastName}`,
        nameContactPerson: obj.nameContactPerson,
        status: obj.status
      }
      applicantData.push(objapplicant);
    })
    setapplicants(applicantData);

  }

  const updateApplicationStatus = (event) => {
    setUpdateVisible(true);
    getRowId(event, (id) => {
      setId(id)
    });
  }

  // Hooks
  // componentDidMount
  useEffect(() => {
    icon();
  });

  // componentDidUpdate
  useEffect(() => {
    getRequest(GET_APPLICATION, getapplicants, {
      [AUTH_TOKEN]: getCookie(AUTH_TOKEN)
    });
    // (getCookie(ADMIN_TYPE) !== CONSUL && navigate('/error'));
  }, []);


  // Template
  return (
    <div class="overflow-y-auto w-100 h-100 px-3 py-5 p-lg-5">
      <div class="m-0 p-0 w-100">
        <h2 class="fs-md-24 fs-24 fw-bold">{ title }</h2>
        <p class="fs-md-14 fs-12 mb-5">{ description }</p>
      </div>
      <Table class="table w-100" columns={columns} data={applicants} actionContent={actionColumnBody} status={true} statusID='status'/>
      <Modal displayModal={updateVisible} onHide={() => setUpdateVisible(false)} superadmin={true} changeapplicationstat={true} id={id} details={ {applicants: true} }/>
    </div>
  );
    
}

export default AdminConsulNewApplicants;