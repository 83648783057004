/**
 * Button Component
 * 
 * @param {string} text
 * @param {function} [onClickAction=()=>{}] onClick
 * @param {function} [onMouseOver=()=>{}] onClick
 * @param {function} [onMouseOut=()=>{}] onClick
 * @param {string} [additionalClass=''] additionalClass
 * @param {string} [addtlLabelClass=''] addtlLabelClass
 * @param {string} [addtlIconClass=''] addtlIconClass
 * @param {boolean} [dropdown=false] dropdown
 * @param {boolean} [icon=false] icon
 * @param {string} [src=''] src
 * @returns Template
 * 
 * @author Angelo C. Bustamante
 * @since 04/09/2024 
 */
let IconButton = ({text, onClickAction=() => {}, onMouseOver=() => {}, onMouseOut=() => {}, addtlButtonClass='', addtlLabelClass='', addtlIconClass='', modal=false, modalTarget='', dropdown=false, icon=false, img=false, src=''}) => {
  // Variable

  // Methods
  // Onclick Handler
  let handler = (e) => {
    e.preventDefault();
    
    onClickAction(e);
  }

  let dataToggle = () => {
    if (dropdown) {
      return 'dropdown';
    }
    
    if (modal) {
      return 'modal';
    }

    return '';
  }

  // Template
  return (
    <button className={`${addtlButtonClass} pe-auto`} data-bs-toggle={ dataToggle() } data-bs-target={ modalTarget } onClick={ handler }>
      { img && <img className={ `me-2 ${addtlIconClass}` } src={src} alt="" onMouseOver={onMouseOver} onMouseOut={onMouseOut}/> }
      { icon && <span className={` me-2 ${addtlIconClass} `}></span> }
      <span className={ `text-uppercase fs-md-14 ${addtlLabelClass}` }>{ text }</span>
    </button>
  );

  // return (
  //   <button class="button button--section bg--blue rounded-2 text-white fw-bold d-flex align-items-center justify-content-center text-uppercase fs-14 ms-auto" type="button" data-bs-toggle={ dropdown && 'dropdown' }>
  //     { icon && <img class="me-2" src={src} alt=""/> }
  //     <span class="text-uppercase fs-md-14">{ text }</span>
  //   </button>
  // );

  // return (
  //   <button class="button bg--blue rounded-2 text-white fw-bold w-100 mt-5 mb-3" type="button">
  //     <span class="text-uppercase fs-md-14 fs-12">Log in</span>
  //   </button>
  // );

  // return (
  //   <button class="button button--section bg--blue rounded-2 text-white fw-bold d-flex align-items-center justify-content-center" type="button" data-bs-toggle="modal" data-bs-target="#modal-addCryptCategory">
  //     <span class="me-2 fas fa-plus"></span>
  //     <span class="text-uppercase fs-md-14">Add Category</span>
  //   </button>
  // );

  // return (
  //   <button class="button button--section bg--blue rounded-2 text-white fw-bold d-flex align-items-center justify-content-center text-uppercase fs-md-14 ms-3" type="button" data-bs-toggle="modal" data-bs-target="#modal-cancelReservation">CANCEL RESERVATION</button>
  // );

  // return (
  //   <button class="button button--section fs-md-14 border border-2 rounded-2 fw-bold d-flex align-items-center justify-content-center border--blue fc-blue" type="button" onclick="window.history.back()">GO BACK</button>
  // );
    
}

export default IconButton;