// Dependencies
import { useEffect, ref, useRef } from "react";
import { useState } from "react";
import { profile } from "../../../js/util";
import { getCookie } from "../../../js/cookie";

let SuperadminProfile = ({ title }) => {
  // Variable
  const [profileData, setProfileData] = useState({});
  const [fullname, setFullname] = useState("");
  const fNameRef = useRef(null);
  const lNameRef = useRef(null);
  const emailRef = useRef(null);
  const profileRef = useRef(null);
  const imageRef = useRef(null);
  const signatureRef = useRef(null);
  // Methods
  // Hooks
  // componentDidMount
  useEffect(() => {
    profile();
    fetch(
      "https://api.retire-ph.com/get_user?auth_token=" +
        getCookie("auth_token"),
      { method: "GET" }
    ).then(async (res) => {
      const data = await res.json();
      console.log(data);
      data["user"]["sep_name"] = data["user"]["name"].split(" ");
      data["user"]["fname"] = data["user"]["sep_name"][0];
      const lname = data["user"]["sep_name"].slice(1).join(" ");
      data["user"]["lname"] = lname;
      setFullname(data["user"]["name"]);
      setProfileData(data["user"]);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", fullname);
    formData.append("email", emailRef.current.value);
    formData.append("auth_token", getCookie("auth_token"));
    formData.append("signature", signatureRef.current.files[0] || "N/A");
    formData.append("profilePicture", imageRef.current.files[0] || "N/A");
    fetch("https://api.retire-ph.com/edit_profile", {
      method: "PUT",
      headers: {
        Authorization: getCookie("auth_token"),
      },
      body: formData,
    }).then(async (res) => {
      alert("Profile updated.");
      const data = await res.json();
      console.log(data);
    });
  };
  // Template
  return (
    <div class="overflow-y-auto w-100 h-100 px-3 py-5 p-lg-5">
      <div class="m-0 p-0 w-100">
        <h2 class="fs-sm-24 fs-24 fw-bold mb-5">{title}</h2>
      </div>
      <div class="tab-content">
        <div
          class="tab-pane fade show active"
          id="profile"
          role="tabpanel"
          aria-labelledby="nav-profile"
          tabindex="0"
        >
          <form onSubmit={handleSubmit}>
            <input
              type="hidden"
              id="name"
              value={fullname}
              defaultValue={profileData && profileData["name"]}
            />
            <div class="d-flex flex-md-row flex-column align-items-center my-5">
              <div class="profile position-relative rounded-circle mb-2 mb-lg-0 me-md-5 border-4 border">
                <img
                  class="absolute rounded-circle object-fit-cover d-block section--profile-img js-profile-image"
                  ref={profileRef}
                  id="profile_view"
                  src={
                    profileData &&
                    "data:image/*;base64," + profileData["profilePicture"]
                  }
                  alt="verify account icon"
                />

                <img
                  class="rounded-circle object-fit-cover d-block section--profile-img js-profile-image"
                  src="img/avatar.png"
                  alt="verify account icon"
                />
              </div>
              <div class="d-flex flex-column justify-content-center mt-4 mt-sm-0">
                <button
                  class="button button--section bg--blue rounded-2 text-white fw-bold mb-3 position-relative"
                  type="button"
                  data-bs-tooltip="tooltip"
                  data-bs-placement="bottom"
                  title="Change your profile photo"
                  onClick={() => {
                    imageRef.current.click();
                  }}
                >
                  <span class="text-uppercase fs-14">CHANGE PHOTO</span>
                </button>
                <input
                  class="top-0 start-0 w-0 h-0 position-absolute opacity-0 z-1"
                  type="file"
                  ref={imageRef}
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      const selectedImage = URL.createObjectURL(
                        e.target.files[0]
                      );
                      profileRef.current.src = selectedImage;
                    }
                  }}
                  id="profile_picture"
                  role="button"
                  accept="image/jpeg, image/png, image/jpg"
                />
                <button
                  class="button button--section fs-14 border border-2 rounded-2 fw-bold d-flex align-items-center justify-content-center border--blue fc-blue"
                  type="button"
                  data-bs-tooltip="tooltip"
                  data-bs-placement="bottom"
                  title="Remove your profile photo"
                  onClick={() => {
                    const view = document.getElementById("profile_view");
                    view.src = "img/avatar.png";
                  }}
                >
                  <span class="text-uppercase fs-14">REMOVE PHOTO</span>
                </button>
              </div>
            </div>

            <div class="col-md-6">
              <div class="row mb-3">
                <div class="d-flex flex-column position-relative col-md-6 mb-3 mb-md-0">
                  <input
                    ref={fNameRef}
                    onChange={(e) => {
                      if (fNameRef && lNameRef) {
                        setFullname(
                          fNameRef.current.value + " " + lNameRef.current.value
                        );
                      }
                    }}
                    class="form__input p-3 fs-14 border--black border border-2 rounded-2"
                    id="firstname"
                    type="text"
                    placeholder="Enter your first name"
                    defaultValue={profileData && profileData["fname"]}
                  />
                  <label
                    class="text-uppercase fw-semibold fs-14 px-1 top-0 position-absolute bg--light-gray"
                    for="firstname"
                  >
                    First name
                  </label>
                </div>
                <div class="d-flex flex-column position-relative col-md-6">
                  <input
                    ref={lNameRef}
                    onChange={(e) => {
                      if (fNameRef && lNameRef) {
                        setFullname(
                          fNameRef.current.value + " " + lNameRef.current.value
                        );
                      }
                    }}
                    class="form__input p-3 fs-14 border--black border border-2 rounded-2"
                    id="lastname"
                    type="text"
                    placeholder="Enter your last name"
                    defaultValue={profileData && profileData["lname"]}
                  />
                  <label
                    class="text-uppercase fw-semibold fs-14 px-1 top-0 position-absolute bg--light-gray"
                    for="lastname"
                  >
                    Last name
                  </label>
                </div>
              </div>
              <div class="row mb-3">
                <div class="d-flex flex-column position-relative col-md-6 mb-3 mb-sm-0">
                  <input
                    class="form__input p-3 fs-14 border--black border border-2 rounded-2"
                    defaultValue={profileData && profileData["email"]}
                    ref={emailRef}
                    id="email-address"
                    type="email"
                    placeholder="Enter your email address"
                  />
                  <label
                    class="text-uppercase fw-semibold fs-14 px-1 top-0 position-absolute bg--light-gray"
                    for="email-address"
                  >
                    Email address
                  </label>
                </div>
                <div class="d-flex flex-column position-relative col-md-6">
                  <img
                    class="object-fit-cover d-block section--profile-img js-profile-image"
                    id="profile_view"
                    src={
                      profileData &&
                      profileData["signature"] &&
                      "data:image/*;base64," + profileData["signature"]
                    }
                    alt="signature icon"
                  />
                  <input
                    ref={signatureRef}
                    class="form__input fs-14 border--black border border-2 rounded-2 section--file-signature"
                    id="signature"
                    type="file"
                  />
                  <label
                    class="text-uppercase fw-semibold fs-14 px-1 top-0 position-absolute bg--light-gray"
                    for="signature"
                  >
                    Signature
                  </label>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-5">
                <button
                  class="button button--section bg--blue rounded-2 text-white fw-bold d-flex align-items-center justify-content-center text-uppercase fs-14 ms-3"
                  type="submit"
                  data-bs-toggle="alert"
                  data-bs-target=""
                >
                  SAVE CHANGES
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SuperadminProfile;
