// Dependencies
import { useEffect } from 'react';
import Button from '../../components/button';
import { Link } from "react-router-dom";

import './retiree.scss';


let RetireeHome = () => {
  // Variable

  // Methods
  // Set Greeting value in header
  let fnGreeting = () => {

    try {
      let arrMonths = [ 'January','February','March','April','May','June','July', 'August','September','October','November','December' ];
      let arrDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      let currentHour = new Date().getHours();
  
      // Get the greeting element
      let greeting = document.getElementById('js-greeting');
      let subgreeting = document.getElementById('js-subgreeting');
  
      if(greeting) {
        // Change the greeting based on the time of day
        if (currentHour >= 5 && currentHour < 12) {
          greeting.textContent = 'Good morning, Ana!';
        } else if (currentHour >= 12 && currentHour < 17) {
          greeting.textContent = 'Good afternoon, Ana!';
        } else {
          greeting.textContent = 'Good evening, Ana!';
        }
      }
  
      if (subgreeting) {
        subgreeting.textContent = `Today is ${ arrDays[new Date().getDay()] }, ${ new Date().getDate() } ${ arrMonths[new Date().getMonth() - 1] } ${ new Date().getFullYear() }`
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Hook for onload
  useEffect(() => {
    fnGreeting();
  });

  // Template
  return (
    <div>
      <div class="banner position-relative">
        <div class="section__container mx-auto w-100 h-100 d-flex align-items-md-center justify-content-between text-white px-3 px-md-4">
          <div class="pt-5 pt-md-0">
            <h2 class="fw-bold page-title fs-md-36 fs-24" id="js-greeting"></h2>
            <span class="fs-14 fs-md-18 page-desc" id="js-subgreeting"></span>
          </div>
          
          <div class="banner__image banner__image--masskara position-absolute position-md-static">
            <img class="w-100 h-100 object-fit-cover" src="img/asset-masskara.png"></img>
          </div>
        </div>
        <div class="banner__image h-100 w-100 position-absolute top-0 z-n1">
          <img class="w-100 h-100 object-fit-cover" src="img/bg-retiree-home.png"></img>
        </div>
      </div>

      <div class="section section__container position-relative z-1 bg-white mx-auto w-100 h-100 px-3 px-md-4">
        <h3 class="fw-bold fs-24 mb-5 text-center">What do you want to do today?</h3>
        <ul class="mt-4 d-flex flex-column flex-wrap flex-md-row justify-content-center align-items-center text-white">
          <li class="rounded-6 me-md-4">
            <Link to="/retiree/application/main" className='button button--retiree bg--blue d-flex flex-column align-items-center justify-content-center rounded-6 px-4 py-5'>
              <div class="button__image">
                <img class="object-fit-cover h-100 w-100" src="img/icon-apply-new.png" alt="Apply New Icon" />
              </div>
              <span class="fs-14 fw-bold text-center mt-3">APPLY NEW APPLICATION</span>
            </Link>
          </li>
          <li class="mt-4 mt-md-0 rounded-6 me-md-4">
            <Link to="/retiree/application/main" className='button button--retiree bg--blue d-flex flex-column align-items-center justify-content-center rounded-6 px-4 py-5'>
              <div class="button__image">
                <img class="object-fit-cover h-100 w-100" src="img/icon-card-profile.png" alt="Apply New Icon" />
              </div>
              <span class="fs-14 fw-bold text-center mt-3">VIEW MY PROFILE</span>
            </Link>
          </li>
          <li class="mt-4 mt-md-4 mt-lg-0 rounded-6">
            <Link to="/retiree/application/profile" className='button button--retiree bg--blue d-flex flex-column align-items-center justify-content-center rounded-6 px-4 py-5'>
              <div class="button__image">
                <img class="object-fit-cover h-100 w-100" src="img/icon-oservices-renewal.png" alt="Apply New Icon" />
              </div>
              <span class="fs-14 fw-bold text-center mt-3">SBMA/CDC PROPERTIES RENEWAL</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
    
}

export default RetireeHome;