import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import './scss/style.scss';

// Templates
import Template from './templates/page-template/Template';
import RetireeTemplate from "./templates/page-template/RetireeTemplate";
import RetireeApplicationPageTemplate from "./templates/page-template/RetireeApplicationPageTemplate";
import RetireeApplicationTemplate from "./templates/page-template/RetireeApplicationTemplate";
import RetireeRenewalTemplate from "./templates/page-template/RetireeRenewalTemplate";
import SuperadminTemplate from "./templates/page-template/SuperadminTemplate";
import AdminTemplate from "./templates/page-template/AdminTemplate";

import Error from "./Error";

// Retiree Components
import RetireeHome from './templates/pages/retiree/retiree-home';
import RetireeProfile from './templates/pages/retiree/retiree-profile';
import RetireeOtherServices from './templates/pages/retiree/retiree-other-services';

import RetireeApplication from './templates/pages/retiree/retiree-application';

// Retiree Application Components
import AppForm1 from "./templates/pages/retiree/application_forms/app_form1";
import ThankYou from "./templates/pages/retiree/application_forms/thank_you";

// Retiree Renewal Components
import RenewalForm from "./templates/pages/retiree/renewal_forms/renewal_form";
import ThankYouRenewal from "./templates/pages/retiree/renewal_forms/thank_you_renewal";

import RetireeApplicationHistory from './templates/pages/retiree/retiree-application-history';

// import RetireeApplicationForm1 from './templates/pages/retiree/retiree-application-form-1';

// Superadmin Components
import SuperadminDashboard from './templates/pages/superadmin/superadmin-dashboard';
import SuperadminRules from './templates/pages/superadmin/superadmin-rules';
import SuperadminAccountMgmt from './templates/pages/superadmin/superadmin-account-mgmt';
import SuperadminNewApplicants from './templates/pages/superadmin/superadmin-new-applicants';
import SuperadminRenewals from './templates/pages/superadmin/superadmin-renewals';
import SuperadminViewApplicant from './templates/pages/superadmin/superadmin-view-applicant';
import SuperadminViewRenewal from './templates/pages/superadmin/superadmin-view-renewal';
import SuperadminProfile from './templates/pages/superadmin/superadmin-profile';

// Admin Components
import AdminConsulRenewals from './templates/pages/admin/admin-consul-renewals';
import AdminConsulNewApplicants from './templates/pages/admin/admin-consul-new-applicants';
import AdminEvalRenewals from './templates/pages/admin/admin-eval-renewals';
import AdminEvalNewApplicants from './templates/pages/admin/admin-eval-new-applicants';
import AdminViewApplicant from "./templates/pages/admin/admin-view-applicant";

// Marketer Components
import MarketerRenewals from './templates/pages/marketer/marketer-renewals';
import MarketerNewApplicants from './templates/pages/marketer/marketer-new-applicants';


import Login from './templates/pages/login';
import Signup from './templates/pages/signup';


let App = () => {


  const [isAgeModal, setIsAgeModal] = useState(false);

  // ADD COOKIE TO CHECK IF USER HAS VISITED THE LANDING PAGE FOR THE FIRST TIME
  const [isLandingModal, setIsLandingModal] = useState(true);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Template />}>
          <Route index element={<Login />} />

          {/* Signup Route */}z
          <Route path="signup" element={<Signup />} />

          {/* Retiree Routes */}
          <Route path="retiree" element={<RetireeTemplate isAgeModal={isAgeModal} setIsAgeModal={setIsAgeModal} isLandingModal={isLandingModal} setIsLandingModal={setIsLandingModal} />} >
            <Route path="home" element={<RetireeHome />} />
            <Route path="profile" element={<RetireeProfile />} />
            <Route path="services" element={<RetireeOtherServices />} />

            <Route path="application" element={<RetireeApplicationPageTemplate />} >

              <Route path="main" element={<RetireeApplication />} />

                {/* NEW APPLICATION ROUTES */}
                <Route path="new_application" element={<RetireeApplicationTemplate setIsAgeModal={setIsAgeModal}/>} >
                  <Route path="form" element={<AppForm1 />} />
                  <Route path="application_confirmation" element={<ThankYou />} />
                </Route>

                {/* RENEWAL APPLICATION ROUTE */}
                <Route path="renewal" element={<RetireeApplicationTemplate setIsAgeModal={setIsAgeModal}/>} >
                  <Route path="form" element={<RenewalForm />} />
                  <Route path="renewal_confirmation" element={<ThankYouRenewal />} />
                </Route>

            </Route>

            <Route path="applicationhistory" element={<RetireeApplicationHistory />} />
            {/* <Route path="applicationform1" element={<RetireeApplicationForm1 />} /> */}
            <Route path="otherservices" element={<RetireeOtherServices />} />
          </Route>

          {/* Superadmin Routes */}
          <Route path="superadmin" element={<SuperadminTemplate />} >
            <Route path="dashboard" element={<SuperadminDashboard />} />
            <Route path="rules" element={<SuperadminRules title="Rules" description="Instructions text"/>} />
            <Route path="accountmgmt" element={<SuperadminAccountMgmt title="Account Management" description="Instructions text"/>} />
            <Route path="newapplicants" element={<SuperadminNewApplicants title="New Applicants" description="Instructions text"/>} />
            <Route path="renewals" element={<SuperadminRenewals title="Renewals" description="Instructions text"/>} />
            <Route path="viewapplicant/:userID" element={<SuperadminViewApplicant/>} />
            <Route path="viewrenewal" element={<SuperadminViewRenewal/>} />
            <Route path="profile" element={<SuperadminProfile title="My Profile" />} />
          </Route>

          {/* Admin Routes */}
          <Route path="admin" element={<AdminTemplate />} >
            <Route path="consulrenewals" element={<AdminConsulRenewals title="Renewals" description="Instructions text" />} />
            <Route path="consulnewapplicants" element={<AdminConsulNewApplicants title="New Applicants" description="Instructions text" />} />
            <Route path="renewals" element={<AdminEvalRenewals title="Renewals" description="Instructions text" />} />
            <Route path="newapplicants" element={<AdminEvalNewApplicants title="New Applicants" description="Instructions text" />} />
            <Route path="profile" element={<SuperadminProfile title="My Profile" />} />
            <Route path="viewapplicant/:userID" element={<AdminViewApplicant />} />
          </Route>

          {/* Marketer Routes */}
          <Route path="marketer" element={<AdminTemplate />} >
            <Route path="renewals" element={<MarketerRenewals title="Renewals" description="Instructions text" />} />
            <Route path="newapplicants" element={<MarketerNewApplicants title="New Applicants" description="Instructions text" />} />
            <Route path="viewapplicant/:userID" element={<AdminViewApplicant />} />
            {/* Change to marketer profile component, with praAccreditationNo */}
            <Route path="profile" element={<SuperadminProfile title="My Profile" />} />
          </Route>

          {/* Error Route */}
          <Route path="*" element={<Error />} />
          <Route path="/error" element={<Error />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );

}

export default App;
