import Header from "../components/header"
import Footer from "../components/footer"
import { Outlet } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom';
import { verifySession } from '../../js/util';
import { useEffect } from "react";

import InvalidAgeModal from "./invalidAgeModal";
import LandingInstructionsModal from "./LandingInstructionsModal";

import "../../tailwind/output.css"
import "../../tailwind/overwrite.css"

function RetireeTemplate({ isAgeModal, setIsAgeModal, isLandingModal, setIsLandingModal }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    verifySession(location.pathname, (bSession) => {
      bSession && navigate('/error');
    });
  }, []);
  

  return (
    <div>

        <InvalidAgeModal isAgeModal={isAgeModal} setIsAgeModal={setIsAgeModal}/>

        <LandingInstructionsModal isLandingModal={isLandingModal} setIsLandingModal={setIsLandingModal}/>        

        <Header />

        <Outlet />

        <Footer />

        

    </div>
  )
}

export default RetireeTemplate